<template>
  <div>
    <form-dialog
      v-if="dialogForm"
      :key="'dialog-' + dialogKey"
      :permissions="permissions"
      :formTitle="formTitle"
      :formInputs="formInputs"
      :formModel="formModel"
      :formMethod="formMethod"
      :loading="loadingForm"
      :fromCRUD="true"
      @error="$emit('error', event)"
      @close="closeForm()"
      @save="save()"
      @reload="dialogKey=new Date().getTime()"
    />

    <delete-dialog
      v-if="dialogDelete"
      :loading="loadingDelete"
      @close="closeDelete()"
      @confirm="deleteItem()"
    />

    <dashboard-table
      :key="tableKey"
      :title="title"
      :headers="headers"
      :data="data"
      :formInputs="formInputs"
      :loading="loadingTable"
      :noEdit="noEdit"
      :noAdd="noAdd"
      :noDelete="noDelete"
      :download="download"
      @add="add()"
      @edit="edit($event)"
      @delete="remove($event)"
      @form-title="formTitle=$event"
    />
  </div>
</template>


<script>
import FormDialog from './children/FormDialog.vue';
import DeleteDialog from './children/DeleteDialog.vue';
import DashboardTable from './children/DashboardTable.vue';
import fetchV2 from '../../assets/js/fetchV2';
import transform from '../../assets/js/transform';

export default {
  name: 'CRUDManagement',

  components: {
    FormDialog,
    DeleteDialog,
    DashboardTable,
  },

  props: {
    permissions: {
      type: Number,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    endpoint: {
      type: String,
      required: true,
    },

    formInputs: {
      type: Object,
      required: true,
    },

    noEdit: {
      type: Boolean,
      required: false,
      default: false,
    },

    noAdd: {
      type: Boolean,
      required: false,
      default: false,
    },

    noDelete: {
      type: Boolean,
      required: false,
      default: false,
    },

    download: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    loadingForm: false,
    loadingTable: false,
    loadingDelete: false,
    dialogForm: false,
    dialogDelete: false,
    formTitle: '',
    formType: '',
    formModel: {},
    formMethod: 'add',
    data: [],
    headers: [],
    defaultItem: {},
    itemEndpointData: {},
    dialogKey: 0,
    fetches: new Set(),
    tableKey: 0,
  }),

  methods: {
    closeForm() {
      this.dialogForm = false;
      this.formModel = Object.assign({}, this.defaultItem);
    },

    closeDelete() {
      this.dialogDelete = false;
      this.formModel = Object.assign({}, this.defaultItem);
    },

    deleteItem() {
      this.loadingDelete = true;
      this.dialogDelete = false;
      this.loadingTable = true;
      this.$emit('loading', 'Deleting item');

      for (const [key, val] of Object.entries(this.formInputs)) {
        if (val.itemsEndpoint) {
          const found = val.items.find(f => f.text === this.formModel[key]);
          if (found) {
            this.formModel[key] = found.value;
          }
        }
      }

      if (this.formModel.id !== undefined) {
        fetchV2('DELETE ' + this.endpoint + '/' + this.formModel.id).then(() => {
          fetchV2('GET ' + this.endpoint).then(data => {
            this.data = transform.from(this.formInputs, data);
            this.loadingTable = false;
            this.loadingDelete = false;
            this.$emit('loading', false);
          }).catch(status => {
            console.error(status);
            this.loadingTable = false;
            this.$emit('loading', false);
          })
        }).catch(status => {
          console.error(status);
          this.loadingTable = false;
          this.$emit('loading', false);
        })
      }
      else {
        fetchV2('DELETE ' + this.endpoint, this.formModel).then(() => {
          fetchV2('GET ' + this.endpoint).then(data => {
            this.data = transform.from(this.formInputs, data);
            this.loadingTable = false;
            this.loadingDelete = false;
            this.$emit('loading', false);
          }).catch(status => {
            console.error(status);
            this.loadingTable = false;
            this.$emit('loading', false);
          })
        }).catch(status => {
          console.error(status);
          this.loadingTable = false;
          this.$emit('loading', false);
        })
      }
    },

    add() {
      this.formType = 'POST';
      this.dialogForm = true;
      this.formTitle = 'Add ' + this.title;
      this.formModel = Object.assign({}, this.defaultItem);
      this.formMethod = 'add';

      Object.keys(this.formInputs).forEach(key => {
        /** Puts the default select value if it is set */
        if (!this.formInputs[key].isHidden && ['select', 'autocomplete'].includes(this.formInputs[key].type) && this.formInputs[key].defaultValue != null)
          this.formModel[key] = this.formInputs[key].defaultValue;
      });
    },

    edit(item) {
      this.formType = 'PUT';
      this.dialogForm = true;
      this.formTitle = 'Edit ' + this.title;
      this.formModel = Object.assign({}, item);
      this.formMethod = 'edit';

      Object.keys(this.formInputs).forEach(key => {
        /** Tranlates item from row into dialog */
        const formInput = this.formInputs[key];
        if (!this.formInputs[key].isHidden && ['select', 'autocomplete'].includes(formInput.type) && formInput.items != null) {
          const arr = formInput.items.filter((e) => {
            return e.text === item[key];
          });
          if (arr.length > 0)
            this.formModel[key] = arr[0].value;
        }
      });
    },

    save() {
      this.dialogForm = false;
      this.loadingForm = false;
      this.loadingTable = true;
      Object.keys(this.formInputs).forEach((item) => {
        if (this.formInputs[item].send === false)
          delete this.formModel[item];
      });
      this.$emit('loading', 'Saving item');
      fetchV2(this.formType + ' ' + this.endpoint, transform.to(this.formInputs, this.formModel)).then(() => {
        fetchV2('GET ' + this.endpoint).then(data => {
          this.data = transform.from(this.formInputs, data);
          this.loadingTable = false;
          this.$emit('loading', false);
        }).catch(err => {
          console.error(err);
          if (err.errorMessage) {
            console.log(err.errorMessage);
            this.$emit('error', err.errorMessage);
          }
          this.loadingTable = false;
          this.$emit('loading', false);
        })
      }).catch(err => {
        console.error(err);
        if (err.errorMessage) {
          console.log(err.errorMessage);
          this.$emit('error', err.errorMessage);
        }
        this.loadingTable = false;
        this.$emit('loading', false);
      })
    },

    remove(item) {
      this.dialogDelete = true;
      this.formModel = Object.assign({}, item);
    },

    populateItemsEndpoint() {
      const key = Array.from(this.fetches).pop();
      fetchV2('GET ' + this.formInputs[key].itemsEndpoint).then(items => {
        this.formInputs[key].items = items.sort((a, b) => { return a.text < b.text ? -1 : a.text > b.text ? 1 : 0 });
        if (this.formInputs[key].items.length > 0)
          this.formModel[key] = this.formInputs[key].items[0].value;
        this.fetches.delete(key);
        this.itemsEndpointCallback();
      }).catch(err => {
        console.error(err);
      })
    },

    itemsEndpointCallback() {
      if (Array.from(this.fetches).length === 0) {
        if (!this.noEdit || !this.noDelete) {
          this.headers.push({ text: 'Actions', value: 'actions', sortable: false })
        }
        this.loadingTable = true;
        this.$emit('loading', 'Items');
        fetchV2('GET ' + this.endpoint).then(data => {
          this.data = transform.from(this.formInputs, data);
          for (const field of Object.keys(this.formInputs)) {
            if (field.items && field.items.length > 0) {
              for (const i in data) {
                data[i][field] = field.items.find(f => f.value == data[i])
              }
            }
          }
          // this.data = data;
          this.loadingTable = false;
          this.$emit('loading', false);
        }).catch(status => {
          console.error(status);
          this.loadingTable = false;
          this.$emit('loading', false);
        })
      }
      else {
        this.populateItemsEndpoint();
      }
    },

  },

  created () {
    Object.keys(this.formInputs).forEach((key) => {
      if (key !== 'id') {
        this.headers.push({
          text: this.formInputs[key].label,
          value: key,
        });
      }

      if (this.formInputs[key].itemsEndpoint != null && this.formInputs[key].items == null)
        this.fetches.add(key);
    });
    if (Array.from(this.fetches).length > 0)
      this.populateItemsEndpoint();
    else
      this.itemsEndpointCallback();
  },
};
</script>
