<template>
  <div
    class="ma-0 mx-auto"
    style="min-height: 100%; max-width: 1000px;"
  >
    <h1
      class="mt-0 mb-5"
      style="color: white;"
    >My Property</h1>

    <div
      v-if="noProperty === false"
    >
      <alerts-panel
        v-if="overduePaymentsProp.length > 0 || upcomingPaymentsProp.length > 0"
        :error="overduePaymentsProp"
        :warning="upcomingPaymentsProp"
      />

      <v-card
        v-if="property"
        class="mt-5 mx-auto pa-5"
        style="width: 100%;"
      >
        <div
          style="width: 100%;"
          class="d-flex flex-column jusity-space-between align-center mb-3"
        >
          <h2
            class="mr-auto text-left"
          >Property Info</h2>

          <h4
            v-if="property.property_url"
            class="mr-auto text-left"
          >
            <a
              :href="getPropertyURL(property.property_url)"
              target="_blank"
            >Whatcom County Property Page</a>
          </h4>
        </div>

        <div
          class="d-flex flex-column justify-space-between
          flex-md-row"
          style="line-height: 1.7rem; font-family: var(--heading-font) !important;"
        >
          <div>
            <h3
              class="text-left"
              style="font-weight: bold;
              color: #222;"
            >Property Details</h3>
            <div>Lot # {{ property.lot }}</div>
            <div>{{ property.member_of_record }}</div>
            <div>{{ property.email }}</div>
            <div>{{ toTel(property.phone) }}</div>
          </div>

          <br>

          <div
            class="text-left text-md-right mt-5 mt-md-0"
          >
            <h3
              class="text-md-right text-left"
              style="font-weight: bold;
              color: #222;"
            >Property Address</h3>
            <div>{{ property.line1 }}</div>
            <div>{{ property.line2 }}</div>

            <br
              v-if="property.license_plates"
            >

            <h3
              v-if="property.license_plates"
              class="text-left text-md-right"
              style="font-weight: bold;
              color: #222;"
            >License Plates</h3>

            <div
              v-if="property.license_plates"
            >{{ property.license_plates }}</div>
          </div>
        </div>

      </v-card>
    </div>

    <div
      v-else-if="noProperty===true"
      class="d-flex flex-column align-center mt-5"
    >
      <h3
        style="color: white;"
      >Something went wrong. No property found!</h3>

      <br>

      <v-btn
        color="accent"
        class="mx-auto"
        style="min-width: 150px;"
        @click="$router.back()"
      >
        <v-icon
          class="mr-1"
        >mdi-arrow-left</v-icon>
        <span>Back</span>
      </v-btn>
    </div>

  </div>
</template>

<script>
/* IMPORTS */
import AlertsPanel from '@/components/AlertsPanel.vue';
import FormDialog from '@/components/crud-management/children/FormDialog'

export default {
  name: '',

  components: {
    AlertsPanel,
    FormDialog,
  },

  props: {
    permissions: {
      type: Number,
      required: true,
    },

    personalModel: {
      type: Object,
      required: true,
    },

    settings: {
      type: Object,
      required: true,
    },

    overduePayments: {
      type: Array,
      required: true,
    },

    upcomingPayments: {
      type: Array,
      required: true,
    },

    generalAlerts: {
      type: Array,
      required: true,
    },

    properties: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    dialogKey: new Date().getTime(),
    property: null,
    connectedUsersFI: {
      user_id: {
        label: 'Email',
        type: 'email',
        required: true,
        email: true
      },
    },
    overduePaymentsProp: [],
    upcomingPaymentsProp: [],
    connectedUserLoading: false,
    connectedUsersModel: {},
    connectedUsersDialog: false,
    connectedUsersDict: [],
    disabled: false,
    noProperty: null,
    proppop: false,
  }),

  watch: {
    properties(val) {
      if (val.length > 0 && this.$route.params.property_id != null && val.filter(f => f.id == this.$route.params.property_id).length > 0 && !this.proppop) {
        this.proppop = true;
        this.noProperty = false;
        this.property = val.filter(f => f.id == this.$route.params.property_id)[0];
      }
      else {
        this.noProperty = true;
      }
    },

    overduePayments: function(val) {
      this.updateAlerts();
    },

    upcomingPayments: function(val) {
      this.updateAlerts();
    },
  },

  methods: {
    updateAlerts() {
      this.overduePaymentsProp = this.overduePayments.filter(f => f.property_id == this.$route.params.property_id);
      this.upcomingPaymentsProp = this.upcomingPayments.filter(f => f.property_id == this.$route.params.property_id);
    },

    toTel: (s) => {
      if (!s) return '';
      let f = s.replace(/[\x2b\x28\x29\x2d\x20]/g, '');
      let t = '';
      for (let i = 0; i < f.length; i++) {
        if (i === 1) t += ' ('; if (i === 4) t += ') '; if (i === 7) t += '-';
        t += f[i];
      }
      return t;
    },

    getPropertyURL: (id) => {
      return 'https://property.whatcomcounty.us/propertyaccess/Property.aspx?cid=0&year=' + (new Date().getFullYear()) + '&prop_id=' + id;
    },
  },

  created () {
    if (this.properties.length > 0 && this.properties.filter(f => f.id == this.$route.params.property_id).length > 0) {
      this.proppop = true;
      this.noProperty = false;
      this.property = this.properties.filter(f => { return f.id == this.$route.params.property_id })[0]
    }
    else if (this.$route.params.property_id == null) {
      this.noProperty = true;
    }
    this.updateAlerts();
  },

};
</script>

<style>
  .v-expansion-panel-content__wrap {
    padding: 0 0 16px 0 !important;
  }

  .hover-highlight:hover {
    background: #eee;
  }
</style>
