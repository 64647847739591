<template>
  <div
    class="ma-0 mx-auto"
    style="min-height: 100%; max-width: 1000px;"
  >
    <v-card
      style="max-width: 700px;"
      class="mx-auto py-2"
    >
      <h1
        class="mb-5"
      >My Rentals</h1>

      <v-autocomplete
        v-model="property_id"
        :items="propertyItems"
        label="Property"
        class="px-5 mb-3"
        hide-details
        dense
        outlined
      />

      <div
        class="d-flex align-center justify-space-around flex-wrap"
        style="width: 100%;"
      >
        <div
          class="d-flex align-center mb-3"
          style="width: 350px;"
        >
          <span
            class="pl-5 pr-3"
            style="width: 110px; text-align: left;"
          >Start Date:</span>

          <v-text-field
            v-model="startDate"
            type="date"
            class="pr-5"
            style="width: 200px"
            dense
            outlined
            hide-details
            :rules="[
              v => !!v || 'Start date is required',
              v => new Date(v).getTime() <= new Date(this.endDate).getTime() || 'Start date must be before end date',
            ]"
          />
        </div>

        <div
          class="d-flex align-center mb-3"
          style="width: 350px;"
        >
          <span
            class="pl-5 pr-3"
            style="width: 110px; text-align: left"
          >End Date:</span>

          <v-text-field
            v-model="endDate"
            type="date"
            class="pr-5"
            style="width: 200px"
            dense
            outlined
            hide-details
            :rules="[
              v => !!v || 'End date is required',
              v => new Date(v).getTime() >= new Date(this.startDate).getTime() || 'End date must be after start date',
            ]"
          />
        </div>
      </div>

      <v-expansion-panels
        v-if="filteredRentals && filteredRentals.length > 0"
        :key="filteredRentals.length"
        class="mx-auto px-0"
        popout
        flat
        tile
        hover
      >
        <v-expansion-panel
          v-for="(r, i) in filteredRentals"
          :key="'payment' + i"
          @click="gotoRentalForm(r)"
          class="mb-1"
        >
          <v-expansion-panel-header
            color="#E6E6E6"
            expand-icon="mdi-open-in-new"
          >
            <span><b>{{ r.lname }} {{ r.line1 }}</b>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{{ convertDate(r.check_in) }} - {{ convertDate(r.check_out) }}</span>
          </v-expansion-panel-header>
        </v-expansion-panel>
      </v-expansion-panels>

      <div
        v-else-if="rentals && rentals.length > 0"
        class="d-flex flex-column align-center pa-3 mx-auto"
        style="width: 400px; max-width: 100%;"
      >
        <h4>You have no rentals in this time range for the selected propert{{ this.property_id === 'all' ? 'ies' : 'y' }}.</h4>
      </div>

      <div
        v-else-if="!loading"
        class="d-flex flex-column align-center pa-3 mx-auto"
        style="width: 400px; max-width: 100%;"
      >
        <h4>You have no rental history.</h4>

        <v-btn
          color="accent"
          class="mx-auto mt-3"
          style="min-width: 150px;"
          @click="$router.back()"
        >
          <v-icon
            class="mr-1"
          >mdi-arrow-left</v-icon>

          <span>Back</span>
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Payments',

  components: {

  },

  props: {
    permissions: {
      type: Number,
      required: true,
    },

    properties: {
      type: Array,
      required: true,
    },

    rentals: {
      type: Array,
      required: true,
    },

    propList: {
      type: Array,
      required: true,
      default: [],
    },

    loading: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    filteredRentals: [],
    property_id: null,
    propertyItems: [],
    months: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    startDate: null,
    endDate: null,
  }),

  watch: {
    propList() {
      this.populatePropertyItems();
    },

    property_id() {
      if (this.$route.query.property != this.property_id) {
        this.$router.replace({ query: { property: this.property_id, start: this.startDate, end: this.endDate } });
      }
      this.filterRentals();
    },

    rentals() {
      this.filterRentals();
    },

    startDate() {
      this.filterRentals();
      if (this.$route.query.start != this.startDate) {
        this.$router.replace({ query: { property: this.property_id, start: this.startDate, end: this.endDate } });
      }
    },

    endDate() {
      this.filterRentals();
      if (this.$route.query.end != this.endDate) {
        this.$router.replace({ query: { property: this.property_id, start: this.startDate, end: this.endDate } });
      }
    },
  },

  methods: {
    populatePropertyItems() {
      this.propertyItems = [{
        text: 'All',
        value: 'all',
      }];

      for (const p of this.propList) {
        this.propertyItems.push(p);
      }
    },

    filterRentals() {
      if (this.property_id === 'all') {
        this.filteredRentals = this.rentals.filter(f => new Date(f.check_in).getTime() >= new Date(this.startDate).getTime() && new Date(f.check_in).getTime() <= new Date(this.endDate).getTime());
      }
      else {
        this.filteredRentals = this.rentals.filter(f => f.property_id === this.property_id && new Date(f.check_in).getTime() >= new Date(this.startDate).getTime() && new Date(f.check_in).getTime() <= new Date(this.endDate).getTime());
      }
    },

    convertDate(d) {
      const d2 = new Date();
      d2.setFullYear(parseInt(d.slice(0, 4)), parseInt(d.slice(5, 7)) - 1, parseInt(d.slice(8, 10)));
      d2.setHours(0, 0, 0);
      return d2.toDateString().slice(4);
    },

    gotoRentalForm(r) {
      if (r === undefined) {
        return '/dashboard/' + this.$route.path.match(/member|manager/i)[0] + '/rental-form';
      }
      else {
        this.$router.push('/dashboard/' + this.$route.path.match(/member|manager/i)[0] + '/rental-form/' + r.id);
      }
    },
  },

  created () {
    this.populatePropertyItems();

    if (parseInt(this.$route.query.property)) {
      this.property_id = parseInt(this.$route.query.property);
    }
    else {
      this.property_id = 'all';
    }

    if (this.$route.query.start) {
      this.startDate = this.$route.query.start;
    }
    else {
      let d = new Date();
      d.setMonth(d.getMonth() - 1);
      this.startDate = d.toISOString().slice(0, 10);
    }

    if (this.$route.query.end) {
      this.endDate = this.$route.query.end;
    }
    else {
      let d = new Date();
      this.endDate = d.toISOString().slice(0, 10);
    }

    this.filterRentals();
  },
};
</script>

<style>

</style>
