module.exports = {
  to(template, item) {
    for (const key of Object.keys(template)) {
      if (template[key] && !template[key].isHidden) {
        if (typeof item[key] === 'string')
          item[key] = item[key].trim();

        // Do not need to transform select/autocomplete because forms already transform
        if (['datetime-local', 'datetime'].includes(template[key].type) && item[key] != null)
          item[key] = new Date(item[key]).toISOString();
        else if (['date'].includes(template[key].type) && item[key] != null)
          item[key] = new Date(item[key]).toISOString().substring(0, 10);
        else if (['tel'].includes(template[key].type) && item[key] != null)
          item[key] = item[key].replace(/[\x28\x29\x20\x2b\x2d]/g, '');
        else if (['number'].includes(template[key].type))
          item[key] = parseFloat(item[key]);
        else if (['boolean'].includes(template[key].type)) {
          if (typeof item[key] === 'string')
            item[key] = (item[key].toLowerCase() === 'false') ? 0 : 1;
        }
        else if (['list'].includes(template[key].type)) {
          if (Array.isArray(item[key]))
            for (let i = 0; i < item[key].length; i++)
              if (typeof item[key][i] === 'string')
                item[key][i] = item[key][i].trim();
          if (typeof item[key] === 'string')
            item[key] = item[key].split(',');
        }
      }
    }
    return item;
  },

  from(template, data) {
    if (data.length > 0) {
      for (const key of Object.keys(data[0])) {
        for (const i in data) {
          
          if (template[key] && !template[key].isHidden) {
            const item = data[i];

            if (['datetime-local', 'datetime'].includes(template[key].type) && item[key] != null)
              item[key] = new Date(item[key]).toISOString();
            else if (['date'].includes(template[key].type) && item[key] != null)
              item[key] = new Date(item[key]).toISOString().substring(0, 10);
            else if (['select', 'autocomplete'].includes(template[key].type) && template[key].items != null) {
              const arr = template[key].items.filter((e) => {
                return e.value === item[key];
              });
              if (arr.length > 0)
                item[key] = arr[0].text;
            }

            else if (['boolean'].includes(template[key].type)) {
              item[key] = item[key] === 0 ? 'False' : 'True';
            }
            
            else if (['tel'].includes(template[key].type) && item[key] != null) {
              let f = item[key].replace(/[\x2b\x28\x29\x2d\x20]/g, '');
              let s = '';
              for (let i = 0; i < f.length; i++) {
                if (i === 1) s += ' ('; if (i === 4) s += ') '; if (i === 7) s += '-';
                s += f[i];
              }
              item[key] = s;
            }

            else if (['list'].includes(template[key].type) && item[key] != null) {
              let s = '';
              item[key].forEach(o => {
                Object.keys(o).forEach(k => {
                  s += o[k] + '\x00 ';
                });
                s = s.slice(0, -1) + ',';
              });
              item[key] = s.slice(0, -1);
            }

            data[i] = item;
          }
        }
      }
    }
    return data;
  }
}
