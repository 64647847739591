<template>
  <v-dialog
    v-model="dialog"
    max-width="400px"
  >
    <v-card
      class="pa-4"
    >
      <h2
        class="pb-4"
      >
        Are you sure you want to delete this item?
      </h2>

      <v-card-actions
        class="d-flex justify-space-around"
      >
        <v-btn
          color="primary"
          text
          @click="$emit('close')"
        >
          Cancel
        </v-btn>

        <v-btn
          color="primary"
          @click="$emit('confirm')"
          :loading="loading"
          style="min-width: 120px;"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogDelete',

  props: {
    loading: {
      type: Boolean,
      required: true,
    }
  },

  data: () => ({
    dialog: true,
  }),

  watch: {
    dialog(val) {
      if (!val)
        this.$emit('close');
    }
  },
};
</script>
