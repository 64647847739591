<template>
  <div
    class="d-flex flex-column align-center justify-center"
  >
    <v-card
      class="pa-5"
      style="max-width: 500px; width: 100%;"
    >
      <div
        v-if="item && item.amount > 0 && paymentLoaded"
        class="mb-5 d-flex flex-column align-center"
      >
        <h1
          class="mb-5"
        >{{ item.name }} Payment</h1>

        <div
          v-if="$route.query.type === 'rental'"
        >
          <h4>{{ property.line1 }} {{ property.line2 }}</h4>
          <h4>{{ convertDate(item.check_in) }} - {{ convertDate(item.check_out) }}</h4>
        </div>

        <div
          v-else-if="$route.query.type === 'violation'"
        >
          <h4>Topic: {{ item.topic }}</h4>
          <h4>Description: {{ item.description }}</h4>
        </div>

        <h4>Payment Due Date - {{ convertDate(item.due_date) }}</h4>
        <table
          cellspacing="0"
          style="width: 90%"
        >
          <thead>
            <tr>
              <th>Item</th>
              <th>%</th>
              <th>$</th>
              <th>Sum</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Original Payment</td>
              <td></td>
              <td></td>
              <td
                v-if="item.interest > 1"
              >${{ (item.subtotal / item.interest).toFixed(2) }}</td>
              <td
                v-else
              >${{ (item.subtotal).toFixed(2) }}</td>
            </tr>
            <tr
              v-if="item.interest > 1"
            >
              <td>Compounded Interest</td>
              <td>{{ ((item.interest - 1) * 100).toFixed(2) }}%</td>

              <td
                v-if="item.type === 'due'"
              >${{ (item.subtotal - (item.subtotal) / item.interest).toFixed(2) }}</td>
              <td
                v-else
              >${{ (item.subtotal- item.subtotal / item.interest).toFixed(2) }}</td>

              <td
                v-if="item.type === 'due'"
              >${{ (item.subtotal).toFixed(2) }}</td>
              <td
                v-else
              >${{ (item.subtotal).toFixed(2) }}</td>
            </tr>
            <tr>
              <td>Processing Fee</td>
              <td>3.50%</td>
              <td>${{ (item.amount - item.subtotal).toFixed(2) }}</td>
              <td>${{ (item.amount).toFixed(2) }}</td>
            </tr>
          </tbody>
        </table>

        <h2
          class="mt-3"
        >Total: <b>${{ item.amount.toFixed(2) }}</b></h2>
      </div>

      <div
        v-else-if="Object.keys(item).length > 0 && paymentLoaded"
        class="d-flex flex-column align-center"
      >
        <h3>This payment is $0</h3>
        <br>
        <v-btn
          color="accent"
          large
          class="mx-auto"
          style="min-width: 150px;"
          @click="$router.back()"
        >
          <v-icon
            class="mr-1"
          >mdi-arrow-left</v-icon>
          <span>Back</span>
        </v-btn>
      </div>

      <div
        v-else-if="Object.keys(item).length === 0 && paymentLoaded"
        class="d-flex flex-column align-center"
      >
        <h3>This payment has been made or does not exist.</h3>
        <br>
        <v-btn
          color="accent"
          large
          class="mx-auto"
          style="min-width: 150px;"
          @click="$router.back()"
        >
          <v-icon
            class="mr-1"
          >mdi-arrow-left</v-icon>
          <span>Back</span>
        </v-btn>
      </div>

      <div
        id="paypal-button-container"
        :style="'max-width: 500px; width: 100%; z-index: 0; ' + (paypalInitialized ? 'opacity: 1;' : 'opacity: 0;')"
      ></div>
    </v-card>

  </div>
</template>

<script>
/* IMPORTS */
import { loadScript } from '@paypal/paypal-js';
import fetchV2 from '../../../assets/js/fetchV2';

export default {
  name: 'Payment',

  components: {

  },

  props: {
    permissions: {
      type: Number,
      required: true,
    },
    properties: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    item: {},
    paymentLoaded: false,
    paypalInitialized: false,
  }),

  methods: {
    convertDate(d) {
      const d2 = new Date();
      d2.setFullYear(parseInt(d.slice(0, 4)), parseInt(d.slice(5, 7)) - 1, parseInt(d.slice(8, 10)));
      d2.setHours(0, 0, 0);
      return d2.toDateString().slice(4);
    },

    getPayment(paypal) {
      return new Promise((resolve, reject) => {
        this.$emit('loading', 'Fetching payment information')
        fetchV2('GET /users/payments/personal?type=' + this.$route.query.type + '&id=' + this.$route.query.id + '&property_id=' + this.$route.query.property_id).then(payment => {
          this.item = payment;
          this.paymentLoaded = true;
          const vue = this;

          if (payment.amount > 0) {
            this.$emit('loading', 'Initializing PayPal');
            this.$emit('loading', false); // Removes the fetching payment information message
            paypal.Buttons({
              style: {
                layout: 'vertical',
                color: 'gold',
                shape: 'rect',
                label: 'pay',
                height: 40,
              },

              oninit(data, actions) {
                vue.init(data, actions);
              },

              onInit(data, actions) {
                vue.init(data, actions);
              },

              createorder(data, actions) {
                return vue.order(data, actions);
              },

              createOrder(data, actions) {
                return vue.order(data, actions);
              },

              onapprove(data, actions) {
                return vue.approve(data, actions);
              },

              onApprove(data, actions) {
                return vue.approve(data, actions);
              },

              onerror(err) {
                vue.error(err);
              },

              onError(err) {
                vue.error(err);
              },

              oncancel(data) {
                vue.cancel(data);
              },

              onCancel(data) {
                vue.cancel(data);
              }
            })
            .render('#paypal-button-container')
          }
          return resolve();
        }).catch(err => {
          console.error(err);
          return reject('There was an error getting your payment information. Please retry later.');
        })
      })
    },

    init(data, actions) {
      this.paypalInitialized = true;
      this.$emit('loading', false);
    },

    order(data, actions) {
      return actions.order.create({
        purchase_units: [{
          amount: {
            currency_code: 'USD',
            value: this.item.amount,
          },
        }],
        application_context: {
          shipping_preference: 'NO_SHIPPING',
        },
      })
    },

    approve(data, actions) {
      this.$emit('loading', 'Capturing payment');
      return actions.order.capture().then(orderData => {
        orderData.create_time = new Date(orderData.create_time).toISOString();
        orderData.type = this.$route.query.type;
        orderData.typeID = this.$route.query.id;
        orderData.property_id = this.$route.query.property_id;

        this.$emit('loading', 'Sending payment');
        this.$emit('loading', false); // Used to remove the capturing payment message
        fetchV2('POST /users/payments', orderData).then((data) => {
          this.$emit('success', {
            message: 'Thank you. Your payment has been received.',
            postCall: ['refresh-payments', 'refresh-alerts'],
            postPage: `/dashboard/${this.$route.path.match(/member|manager/i[0])}/payments`
          });
        }).catch(err => {
          this.$emit('error', {
            message: 'Your payment has been processed, but we had issues communicating with our database. Please contact ' + process.env.VUE_APP_SUPPORT_EMAIL + ' with this information ID: ' + details.id + ' to resolve the issue. NOTE: Your payment has been paid even though the alert shows up.',
            postCall: ['refresh-payments', 'refresh-alerts'],
            postPage: `/dashboard/${this.$route.path.match(/member|manager/i[0])}/my-account`
          });
        })
      })
    },

    error(err) {
      this.$emit('error', 'There was an error capturing your payment. Please retry later.');
    },

    cancel(data) {

    }
  },

  mounted() {
    this.item = {};
    this.paymentLoaded = false;
    this.paypalInitialized = false;
    this.$emit('loading', 'Initializing PayPal');
    loadScript({
      'client-id': process.env.VUE_APP_PAYPAL_CLIENT_ID,
      currency: 'USD',
    }).then(paypal => {
      this.$emit('loading', 'Payment information');
      this.$emit('loading', false); // Removes the initializing PayPal message
      this.getPayment(paypal).then(() => {
        this.$emit('loading', false);
      }).catch(err => {
        this.$emit('error', err ? err : 'There was an error initializing PayPal. Please retry later.');
      })
    }).catch(err => {
      this.$emit('error', 'It looks like something is blocking PayPal from loading on this site. Please disable any browser extensions that may be blocking this. uBlock Origin is a common issue.')
    })
  }
}
</script>

<style scoped>
th, td {
  padding: 4px 6px;
}

th {
  border-bottom: 1px solid black !important;
}

th:nth-child(1) {
  text-align: left;
}

.paypal-button, .paypal-button-shape-rect {
  border-radius: 0px !important;
}
</style>
