const fetch = require('node-fetch');
const API_BASE_URL = ['127.0.0.1', 'localhost'].includes(window.location.host.split(':')[0]) ? 'http://127.0.0.1:3000' : process.env.VUE_APP_API_BASE_URL;
console.log('API_BASE_URL: ' + API_BASE_URL);

function refresh(req, body, headers={}) {
  return new Promise((resolve, reject) => {
    const refreshOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      redirect: 'follow',
      referrer: 'no-referrer',
    }
    fetch(API_BASE_URL + '/users/refresh', refreshOptions).then(res => {
      if (!res.ok) {
        return reject(res.status);
      }
      return getData(req, body, headers)
        .then((data2, status2) => resolve(data2, status2))
        .catch(err => reject(err));
    }).catch(err => reject(err))
  })
}

function getData(req, body={}, headers={}) {
  return new Promise((resolve, reject) => {
    const method = req.split(' ')[0];
    const route = req.split(' ')[1];
    const options = {
      method: method,
      headers: headers,
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      redirect: 'follow',
      referrer: 'no-referrer',
    }

    if (headers['Content-Type'] == null) {
      headers['Content-Type'] = 'application/json';
    }

    if (Object.keys(body).length > 0) {
      if (body != null && typeof body === 'object') {
        options.body = JSON.stringify(body);
      }
      if (body != null && typeof body === 'string') {
        options.body = body;
      }
    }

    fetch(API_BASE_URL + route, options).then(res => {
      if (req !== 'GET ' && process.env.NODE_ENV === 'development') {
        console.log(method + ' ' + API_BASE_URL + route + ' - ' + res.status);
      }

      if (!res.ok) {
        if (res.status === 401) {
          return refresh(req, body, headers)
            .then((data, status) => resolve(data, status))
            .catch(err => reject(err));
        }
        else if (res.status === 400) {
          const cType = res.headers.get('content-type') != null ? res.headers.get('content-type') : res.headers.get('Content-Type') != null ? res.headers.get('Content-Type') : '';
          if (/application\/json/.test(cType)) {
            res.json()
              .then(data => reject(data, res.status))
              .catch(err => reject(err));
          }
          else {
            res.text()
              .then(data => reject(data, res.status))
              .catch(err => reject(err));
          }
        }
        else {
          return reject(res.status);
        }
      }
      else if (Math.floor(res.status / 100) === 2) {
        const cType = res.headers.get('content-type') != null ? res.headers.get('content-type') : res.headers.get('Content-Type') != null ? res.headers.get('Content-Type') : ''
        if (/application\/json/.test(cType)) {
          res.json()
            .then(data => resolve(data, res.status))
            .catch(err => reject(err));
        }
        else {
          res.text()
            .then(data => resolve(data, res.status))
            .catch(err => reject(err));
        }
      }
    }).catch(err => {
      console.error(req + ' - ' + err);
      reject(err)
    })
  })
}

module.exports = (req, body={}, headers={}) => {
  return getData(req, body, headers);
}
