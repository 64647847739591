<template>
  <div
    class="d-flex flex-column align-center px-0"
  >
    <h1
      class="mt-5"
    >Email Campaigns</h1>

    <div
      style="max-width: 800px; width: 100%;"
    >
      <v-card
        class="pa-5 ma-5 mx-auto"
        style="width: 100%;"
      >
        <h2
          class="text-left"
        >Send to</h2>

        <v-select
          v-model="formModel.email_list"
          :items="emailLists"
          hide-details
          dense
        />

        <h2
          class="text-left mt-5"
        >Subject</h2>

        <v-text-field
          v-model="formModel.subject"
          type="text"
          hide-details
          dense
        />

        <h2
          class="text-left mt-5"
        >Date</h2>

        <v-text-field
          v-model="formModel.date"
          type="date"
          hide-details
          dense
        />

        <div
          class="d-flex mt-5 mb-2"
        >
          <h2
            class="text-left"
          >Greeting</h2>

          <v-switch
            v-model="customGreeting"
            label="Custom"
            class="ma-0 mt-1 ml-3"
            hide-details
            dense
          />
        </div>

        <v-select
          v-if="!customGreeting"
          v-model="formModel.greeting"
          :items="greetings"
          hide-details
          dense
        />

        <v-text-field
          v-else
          v-model="formModel.greeting"
          placeholder="Custom greeting"
          hide-details
          dense
        />

        <h2
          class="text-left mt-5"
        >Body</h2>

        <v-textarea
          v-model="formModel.body"
          :rows="10"
          outlined
        />

        <div
          class="d-flex mt-5 mb-2"
        >
          <h2
            class="text-left"
          >Sign-off</h2>

          <v-switch
            v-model="customSignoff"
            label="Custom"
            class="ma-0 mt-1 ml-3"
            hide-details
            dense
          />
        </div>

        <v-select
          v-if="!customSignoff"
          v-model="formModel.signoff"
          :items="signoffs"
          hide-details
          dense
        />

        <v-text-field
          v-else
          v-model="formModel.signoff"
          placeholder="Custom sign-off"
          hide-details
          dense
        />
      </v-card>

      <div
        class="d-flex justify-end"
        style="width: 100%;"
      >
        <v-btn
          color="accent"
          class="mx-2 my-5"
          @click="showIframe()"
        >Preview</v-btn>

        <v-btn
          color="success"
          class="mx-2 my-5"
          @click="sendMail()"
        >Send</v-btn>
      </div>

    </div>

    <v-dialog
      v-model="iframeDialog"
      max-width="700px"
    >
      <iframe-dialog
        :key="formModelKey"
        :html="html"
        @closeDialog="iframeDialog=false"
      />
    </v-dialog>

  </div>
</template>

<script>
/* IMPORTS */
import IframeDialog from '@/components/IframeDialog.vue';
import fetchV2 from '../../../assets/js/fetchV2';

export default {
  name: 'EmailCampaign',

  components: {
    IframeDialog,
  },

  props: {
    permissions: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    formModel: {
      email_list: null,
      date: null,
      greeting: '',
      body: '',
      signoff: '',
    },
    formModelKey: 0,
    iframeDialog: false,
    customGreeting: false,
    customSignoff: false,
    greetings: [],
    signoffs: [],
    emailLists: [],
    html: '',
    plaintext: '',
  }),

  watch: {
    customGreeting(val) {
      if (val) {
        this.formModel.greeting = '';
      }
      else {
        this.formModel.greeting = this.greetings[0].value;
      }
    },

    customSignoff(val) {
      if (val) {
        this.formModel.signoff = '';
      }
      else {
        this.formModel.signoff = this.signoffs[0].value;
      }
    }
  },

  methods: {
    showIframe() {
      this.html = this.generateHTML();
      this.plaintext = this.generatePlaintext();
      this.formModelKey = new Date().getTime();
      this.iframeDialog = true
    },

    generateHTML() {
      let head = '<head><style>@import url("https://fonts.googleapis.com/css2?family=Pirata+One&display=swap"); p {margin: 20px 20px;}</style></head>';

      let header = '<div style="width: 100%; height: 60px; overflow: hidden; white-space: normal;"><img height="50px" style="height: 50px;" src="/logo.png" /><span style="font-family: Pirata One; letter-spacing: 4px; font-weight: 400; margin-left: 15px; font-size: 30px; position: relative; top: -11px;">' + process.env.VUE_APP_CLUB_NAME + '</span></div><p>Date: ' + this.formModel.date + '</p>'

      let greeting = '<p>' + this.formModel.greeting + ',</p>';

      let body = '<p><br>' + this.formModel.body.replace(/\n/gi, '<br>').replace(/ /gi, '&nbsp;').replace(/\t/gi, '&nbsp;&nbsp;&nbsp;&nbsp;') + '</p>';

      let signoff = '<p><br>Thank you for your membership and support of our ' + process.env.VUE_APP_LONG_TITLE + ' community.<br><br>Sincerely,<br><br>' + this.formModel.signoff + '</p><br><br>';

      let footer = '<p style="font-size: 14px;">You can unsubscribe from this email list in the <a href="' + process.env.VUE_APP_PORTAL_URL + '">Member\'s Portal</a></p>';

      return head + '<body style="background: #FAFAFB; max-width: 700px; width: 100%; white-space: normal;">' + header + greeting + body + signoff + footer +  '</body>';
    },

    generatePlaintext() {
      let header = process.env.VUE_APP_CLUB_NAME + '\n\nDate: ' + this.formModel.date + '\n\n';

      let greeting = this.formModel.greeting + ',\n\n\n';

      let body = this.formModel.body;

      let signoff = '\n\n\nThank you for your membership and support of our ' + process.env.VUE_APP_LONG_TITLE + ' community.\n\nSincerely,\n\n' + this.formModel.signoff + '\n\n';

      let footer = 'You can unsubscribe from this email list in the Member\'s Portal';

      return header + greeting + body + signoff + footer;
    },

    sendMail() {
      this.$emit('loading', 'Sending email');
      fetchV2('POST /users/email', {
        email_list: this.formModel.email_list,
        subject: this.formModel.subject,
        html: this.generateHTML(),
        text: this.generatePlaintext(),
      }).then(data => {
        this.$emit('success', 'Emails have been queued');
      }).catch(err => {
        this.$emit('error', 'An error has occurred. Please contact ' + process.env.VUE_APP_SUPPORT_EMAIL + '.');
      })
    }
  },

  computed: {

  },

  created () {
    this.greetings = process.env.VUE_APP_EMAIL_GREETINGS.split(',');
    this.signoffs = process.env.VUE_APP_EMAIL_SIGNOFFS.split(',');
    // Map VUE_APP_EMAIL_LIST_NAMES to an array of objects with text property and VUE_APP_EMAIL_LIST_VALUES as value property
    this.emailLists = process.env.VUE_APP_EMAIL_LIST_NAMES.split(',').map((item, i) => {
      return {
        text: item,
        value: process.env.VUE_APP_EMAIL_LIST_VALUES.split(',')[i],
      }
    });

    const d = new Date();
    this.formModel.date = d.getFullYear() + '-' + (d.getMonth()+1).toString().padStart(2, '0') + '-' + d.getDate().toString().padStart(2, '0');
    this.formModel.greeting = this.greetings[0];
    this.formModel.signoff = this.signoffs[0];
    this.formModel.email_list = this.emailLists[0].value;
  },

  mounted () {

  },
}
</script>

<style scoped>

</style>
