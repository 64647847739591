import { render, staticRenderFns } from "./LoaderV2.vue?vue&type=template&id=593b3217&scoped=true&"
import script from "./LoaderV2.vue?vue&type=script&lang=js&"
export * from "./LoaderV2.vue?vue&type=script&lang=js&"
import style0 from "./LoaderV2.vue?vue&type=style&index=0&id=593b3217&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "593b3217",
  null
  
)

export default component.exports