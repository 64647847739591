<template>
  <div>
    <router-view
      :key="$route.fullPath"
      @loading="$emit('loading', $event)"
    />
  </div>
</template>

<script>
/* IMPORTS */

export default {
  name: 'Guest',

  components: {

  },

  props: {
    permissions: {
      type: Number,
      required: true,
    }
  },

  data: () => ({

  }),

  watch: {

  },

  methods: {

  },

  computed: {

  },

  created () {

  },

  mounted () {

  },
};
</script>

<style scoped>

</style>
