<template>
  <v-card
    class="pa-5"
    :flat="flat"
    :tile="tile"
  >
    <v-card-title
      class="pa-0 mb-5"
    >
      <b
        class="headline"
      >
        {{ formTitle }}
      </b>
    </v-card-title>

    <v-form
      v-if="loadComplete"
      ref="form"
      @submit="save"
      lazy-validation
    >
      <div
        v-for="(item, i) in formInputs"
        :key="'input-' + i"
      >
        <v-autocomplete
          v-if="!item.isHidden && item.type === 'autocomplete'"
          v-model="formModel[i]"
          :key="'autocomplete-' + inputKey"
          :items="item.items"
          item-text="text"
          item-value="value"
          :hint="item.hint"
          :persistent-hint="true"
          :prepend-icon="item.prepend"
          :prepend-inner-icon="item.prependInner"
          :prefix="item.prefix"
          :append-icon="item.append"
          :append-inner-icon="item.appendInner"
          :suffix="item.suffix"
          :label="item.label"
          :rules="item.rules"
          :disabled="item.isDisabled"
          :error-messages="item.errorMessages"
        />

        <v-combobox
          v-else-if="!item.isHidden && item.type === 'combobox'"
          v-model="formModel[i]"
          :key="'combobox-' + inputKey"
          :items="item.items"
          item-text="text"
          item-value="value"
          :hint="item.hint"
          :persistent-hint="true"
          :prepend-icon="item.prepend"
          :prepend-inner-icon="item.prependInner"
          :prefix="item.prefix"
          :append-icon="item.append"
          :append-inner-icon="item.appendInner"
          :suffix="item.suffix"
          :label="item.label"
          :rules="item.rules"
          :disabled="item.isDisabled"
          :error-messages="item.errorMessages"
        />

        <v-select
          v-else-if="!item.isHidden && item.type === 'select'"
          v-model="formModel[i]"
          :key="'select-' + inputKey"
          :items="item.items"
          item-text="text"
          item-value="value"
          :hint="item.hint"
          :persistent-hint="true"
          :prepend-icon="item.prepend"
          :prepend-inner-icon="item.prependInner"
          :prefix="item.prefix"
          :append-icon="item.append"
          :append-inner-icon="item.appendInner"
          :suffix="item.suffix"
          :label="item.label"
          :rules="item.rules"
          :disabled="item.isDisabled"
          :error-messages="item.errorMessages"
        />

        <v-textarea
          v-else-if="!item.isHidden && item.type === 'textarea'"
          v-model="formModel[i]"
          outlined
          :rows="item.rows"
          :prepend-icon="item.prepend"
          :prepend-inner-icon="item.prependInner"
          :prefix="item.prefix"
          :append-icon="item.append"
          :append-inner-icon="item.appendInner"
          :suffix="item.suffix"
          :label="item.label"
          :rules="item.rules"
          :disabled="item.isDisabled"
          :error-messages="item.errorMessages"
        />

        <div
          v-else-if="!item.isHidden && item.type === 'list'"
          class="mb-3"
          :key="i"
        >
          <div
            class="v-text-field__slot mb-3"
          >
            <label
              v-if="item.label"
              class="v-label v-label--active theme--light"
              style="font-size: 18px;color: #000 !important;"
            >
              {{ item.label }}:
            </label>

            <div
              v-if="item.hint"
              class="v-text-field__details mt-2"
            >
              <div
                class="v-messages theme--light"
              >
                <div
                  class="v-messages__wrapper"
                >
                  <div
                    class="v-messages__message"
                  >
                    {{ item.hint }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- List container -->
          <div
            class="d-flex flex-column"
          >
            <!-- Each list item loop -->
            <v-card
              v-for="(subItem, j) in formModel[i]"
              :key="i  + '-' + j"
              class="pa-2 pt-4 mb-3 px-5"
              style="position: relative; width: 100%;"
            >
              <!-- Each input field loop -->
              <div
                v-for="(listItem, k) in item.template"
                :key="i + '-' + j + '-' + k"
              >
                <v-text-field
                  v-model="formModel[i][j][k]"
                  :disabled="item.isDisabled"
                  :label="listItem.label"
                  class="mr-3"
                  :rules="listItem.rules"
                  dense
                />
              </div>

              <!-- End Each input field loop -->
              <v-btn
                v-if="!item.isDisabled && (item.min == null || formModel[i].length > item.min)"
                fab
                x-small
                color="error"
                @click="removeListItem(i, j)"
                style="position: absolute; top: -12px; right: -12px;"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            <!-- End Each list item loop -->
            </v-card>
          <!-- End list container -->
          </div>

          <v-btn
            v-if="!item.isDisabled && (item.max == null || formModel[i].length < item.max)"
            class="mb-3"
            @click="addListItem(i)"
            color="secondary"
            small
          >

            + {{ item.label }}
          </v-btn>

          <div
            v-if="item.infoMessage"
            class="v-text-field__details"
          >
            <div
              class="v-messages theme--light info--text"
            >
              <div
                class="v-messages__wrapper"
              >
                <div
                  class="v-messages__message"
                >
                  {{ item.infoMessage }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <v-text-field
          v-else-if="!item.isHidden && item.type === 'tel'"
          v-model="formModel[i]"
          @keydown="telUpdate=i"
          @keyup="updateTel"
          :type="item.type"
          :step="item.step"
          :prepend-icon="item.prepend"
          :prepend-inner-icon="item.prependInner"
          :prefix="item.prefix"
          :append-icon="item.append"
          :append-inner-icon="item.appendInner"
          :suffix="item.suffix"
          :label="item.label"
          :rules="item.rules"
          :placeholder="item.placeholder"
          :disabled="item.isDisabled"
          :error-messages="item.errorMessages"
        />

        <v-switch
          v-else-if="!item.isHidden && (item.type === 'switch' || item.type === 'boolean')"
          v-model="formModel[i]"
          :label="item.label"
          :disabled="item.isDisabled"
          :error-messages="item.errorMessages"
          @change="inputKey=new Date().getTime()"
        />

        <div
          v-else-if="!item.isHidden && item.type === 'calculated'"
          :key="'calculated-' + inputKey"
        >
          <span>{{ item.label }}</span>
          <span
            v-if="!termsNull(item.terms)"
          >
            {{ item.prepend }}

            <span
              v-if="item.calc === 'difference'"
            >
              <span
                v-if="item.termType === 'date'"
              >
                {{ formModel[i] = millis2(new Date(formModel[item.terms[0]]).getTime() - new Date(formModel[item.terms[1]]).getTime(), item.resultUnit) }}
              </span>

              <span
                v-else-if="item.termType === 'number'"
              >
                {{ formModel[i] = (formModel[item.terms[0]] - formModel[item.terms[1]]) }}
              </span>
            </span>

            {{ item.append }}

          </span>

          <span v-else>N/A</span>
        </div>

        <div
          v-else-if="!item.isHidden && item.type === 'if'"
          :key="'if-' + inputKey"
        >
          <span>{{ item.label }}</span>

          <span
            v-if="!termsNull(item.terms)"
          >
            {{ item.prepend }}

            <span
              v-if="expTrue(item.terms, item.op, item.exp)"
            >
              {{ formModel[i]=item.return[0] }}
            </span>

            <span
              v-else
            >
              {{ formModel[i]=item.return[1] }}
            </span>

            {{ item.append }}

          </span>

          <span v-else>N/A</span>
        </div>

        <v-text-field
          v-else-if="!item.isHidden"
          v-model="formModel[i]"
          :type="item.type"
          :step="item.step"
          :hint="item.hint"
          :persistent-hint="true"
          :prepend-icon="item.prepend"
          :prepend-inner-icon="item.prependInner"
          :prefix="item.prefix"
          :append-icon="item.append"
          :append-inner-icon="item.appendInner"
          :suffix="item.suffix"
          :label="item.label"
          :rules="item.rules"
          :placeholder="item.placeholder"
          :disabled="item.isDisabled"
          :error-messages="item.errorMessages"
        />

        <div
          v-if="item.hint"
          class="pa-2"
          style="width: 100%;"
        ></div>
      </div>

      <v-card-actions
        v-if="closeButton || saveButton"
        class="d-flex justify-space-around pb-0"
      >
        <v-btn
          v-if="closeButton"
          color="primary"
          text
          @click="close"
        >Cancel</v-btn>

        <v-btn
          v-if="saveButton"
          type="submit"
          color="primary"
          :loading="loading"
          style="min-width: 120px;"
          @click="save"
        >Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import fetchV2 from '../../../assets/js/fetchV2';
import transform from '../../../assets/js/transform';

export default {
  name: 'FormCard',

  props: {
    permissions: {
      type: Number,
      required: true,
    },

    formTitle: {
      type: String,
      required: true,
    },

    formInputs: {
      type: Object,
      required: true,
    },

    formModel: {
      type: Object,
      required: true,
    },

    formMethod: {
      type: String,
      required: false,
      default: 'add',
    },

    loading: {
      type: Boolean,
      required: true,
    },

    flat: {
      type: Boolean,
      required: false,
      default: false,
    },

    tile: {
      type: Boolean,
      reuired: false,
      default: false,
    },

    closeButton: {
      type: Boolean,
      required: false,
      default: false,
    },

    saveButton: {
      type: Boolean,
      required: false,
      default: true,
    },

    fromCRUD: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    formErrors: {},
    rules: {},
    telUpdate: null,
    reload: false,
    fetches: new Set(),
    inputKey: 0,
    loadComplete: false,
  }),

  watch: {
    formMethod(v) {
      Object.keys(this.formInputs).forEach((key) => {
        this.setDisabledHidden(key, v);
      })
    }
  },

  methods: {
    close() {
      this.$emit('close');
    },

    save(e) {
      e.preventDefault();
      if (e.pageX !== 0 && e.pageY !== 0) {
        if (!this.$refs.form.validate())
          return;
        this.$emit('save');
      }
    },

    getDate(d) {
      if (d === undefined)
        d = new Date();
      return d.getFullYear() + '-' + (d.getMonth()+1).toString().padStart(2, '0') + '-' + d.getDate().toString().padStart(2, '0');
    },

    removeListItem(key, idx) {
      if ((this.formInputs[key].min != null && this.formModel[key].length > this.formInputs[key].min) || this.formInputs[key].min == null) {
        this.formModel[key].splice(idx, 1);
        this.formInputs[key].infoMessage = null;
        this.$forceUpdate();
      }
      else {
        let newKey = key;
        if (key[key.length-1] === 's' && this.formInputs[key].min === 1)
          newKey = key.slice(0, -1)
        this.formInputs[key].infoMessage = 'Cannot have less than ' + this.formInputs[key].min + ' ' + newKey;
        this.$forceUpdate();
      }
    },

    addListItem(key) {
      if ((this.formInputs[key].max != null && this.formModel[key].length < this.formInputs[key].max) || this.formInputs[key].max == null) {
        this.formModel[key].push(Object());
        this.formInputs[key].infoMessage = null;
        this.$forceUpdate();
      }
      else {
        let newKey = key;
        if (key[key.length-1] === 's' && this.formInputs[key].max === 1)
          newKey = key.slice(0, -1)
        this.formInputs[key].infoMessage = 'Cannot have more than ' + this.formInputs[key].max + ' ' + newKey;
        this.$forceUpdate();
      }
    },

    setDisabledHidden(key, view) {
      if ((this.formInputs[key].disabled instanceof Object && this.formInputs[key].disabled[view] === true) || this.formInputs[key].disabledOverride === true)
        this.formInputs[key].isDisabled = true;
      else
        this.formInputs[key].isDisabled = false;

      if (this.formInputs[key].hidden instanceof Object && this.formInputs[key].hidden[view] === true)
        this.formInputs[key].isHidden = true;
      else
        this.formInputs[key].isHidden = false;
    },

    updateTel(e) {
      if (e.key !== 'Backspace' && e.key !== 'Tab' && e.key !== 'Enter') {
        if (e.key !== '0' && !parseInt(e.key) || this.formModel[this.telUpdate].length > 16)
          this.formModel[this.telUpdate] = this.formModel[this.telUpdate].slice(0, -1);
        let f = this.formModel[this.telUpdate].replace(/[\x2b\x28\x29\x2d\x20]/g, '');
        let s = '';
        for (let i = 0; i < f.length; i++) {
          s += f[i];
          if (i === 0)
            s += ' (';
          if (i === 3)
            s += ') ';
          if (i === 6 )
            s += '-';
        }
        this.formModel[this.telUpdate] = s;
      }
    },

    termsNull(a) {
      for (let i = 0; i < a.length; i++) {
        if (this.formInputs[a[i]].type === 'switch' && this.formModel[a[i]] == null) {
          if (this.formInputs[a[i]].default !== undefined)
            this.formModel[a[i]] = this.formInputs[a[i]].default;
          else
            this.formModel[a[i]] = false;
        }
        else if (this.formModel[a[i]] === null || this.formModel[a[i]] === undefined)
          return true;
      }
      return false;
    },

    expTrue(terms, ops, exps) {
      for (let i = 0; i < terms.length; i++) {
        if (ops[i] === '==') {
          if (this.formModel[terms[i]] != exps[i])
            return false;
        }
        else if (ops[i] === '===') {
          if (this.formModel[terms[i]] !== exps[i])
            return false;
        }
        else if (ops[i] === '<') {
          if (this.formModel[terms[i]] >= exps[i])
            return false;
        }
        else if (ops[i] === '>') {
          if (this.formModel[terms[i]] <= exps[i])
            return false;
        }
        else if (ops[i] === '<=') {
          if (this.formModel[terms[i]] > exps[i])
            return false;
        }
        else if (ops[i] === '>=') {
          if (this.formModel[terms[i]] < exps[i])
            return false;
        }
      }
      return true;
    },

    millis2(v, unit) {
      if (unit === 'day')
        return v / 86400000;
      return v;
    },

  },

  created() {
    this.rules = {
      required: v => (v != null && v !== '') || 'Required field',
      inFuture: v => v > this.getDate() || 'Date must be at least one day from current date',
      email: v => (v == null || v == '') || /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(v) || 'Format example@domain.com',
      ge0: v => v >= 0 || 'Number must be greater than or equal to 0',
      le100: v => v <= 100 || 'Number must be lower than 100',
      phoneFormat: v => (v == null || v == '') || /^(0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) || 'Format # (###) ###-####',
      phoneLength: v => (v == null || v == '') || (v != null && v.length <= 16) || 'Phone number too long',
      phoneLength2: v => (v == null || v == '') || (v != null && v.length == 16) || 'Phone number not long enough',
    };

    for (const key of Object.keys(this.formInputs)) {
      this.formErrors[key] = Array();

      if (this.formInputs[key].prefill != null && this.formModel[key] == null)
        this.formModel[key] = this.formInputs[key].prefill;

      if (!this.formInputs[key].hidden instanceof Object || (this.formInputs[key].hidden instanceof Object && this.formInputs[key].hidden.add === false))
        this.defaultItem[key] = null;

      if (this.formInputs[key].rules instanceof Array === false) {
        this.formInputs[key].rules = Array();
        if (this.formInputs[key].template) {
          for (const key2 of Object.keys(this.formInputs[key].template)) {
            if (this.formInputs[key].template[key2].rules instanceof Array === false)
              this.formInputs[key].template[key2].rules = Array();
          }
        }
      }

      if (this.formInputs[key].phone && this.formInputs[key].rules.find((e) => e == this.rules.phoneFormat) === undefined) {
        this.formInputs[key].rules.unshift(this.rules.phoneFormat);
        this.formInputs[key].rules.unshift(this.rules.phoneLength);
        this.formInputs[key].rules.unshift(this.rules.phoneLength2);
      }

      if (this.formInputs[key].ge0 && this.formInputs[key].rules.find((e) => e == this.rules.ge0) === undefined)
        this.formInputs[key].rules.unshift(this.rules.ge0);

      if (this.formInputs[key].le100 && this.formInputs[key].rules.find((e) => e == this.rules.le100) === undefined)
        this.formInputs[key].rules.unshift(this.rules.le100);

      if (this.formInputs[key].type === 'date' && this.formInputs[key].inFuture && this.formInputs[key].rules.find((e) => e == this.rules.inFuture) === undefined)
        this.formInputs[key].rules.unshift(this.rules.inFuture);

      if (this.formInputs[key].email && this.formInputs[key].rules.find((e) => e == this.rules.email) === undefined)
        this.formInputs[key].rules.unshift(this.rules.email);

      if (this.formInputs[key].required && this.formInputs[key].rules.find((e) => e == this.rules.required) === undefined)
        this.formInputs[key].rules.unshift(this.rules.required);

      if (this.formInputs[key].type === 'list') {
        for (const key2 of Object.keys(this.formInputs[key].template)) {
          if (this.formInputs[key].template[key2].required && this.formInputs[key].template[key2].rules.find((e) => e == this.rules.required) === undefined) {
            this.formInputs[key].template[key2].rules.unshift(this.rules.required);
          }
        }
      }

      if (this.formInputs[key].type === 'boolean') {
        if (typeof this.formModel[key] === 'string')
          this.formModel[key] = (this.formModel[key].toLowerCase() === 'false') ? 0 : 1;
      }

      if (this.formInputs[key].type === 'list') {
        if (typeof this.formModel[key] === 'string') {
          this.formModel[key] = this.formModel[key].split(',');
          const keys = Object.keys(this.formInputs[key].template);
          const arr = [];
          this.formModel[key].forEach((e) => {
            const o = {};
            keys.forEach((k, i) => {
              o[k] = e.split('\x00 ')[i];
            });
            arr.push(o);
          });
          this.formModel[key] = arr;
        }
        else if (Array.isArray(this.formModel[key])) {
          if (this.formInputs[key].min != null)
            for (let i = this.formModel[key].length; i < this.formInputs[key].min; i++)
              this.formModel[key].push(Object());
        }
        else {
          this.formModel[key] = Array();
          if (this.formInputs[key].min != null)
            for (let i = 0; i < this.formInputs[key].min; i++)
              this.formModel[key].push(Object());
        }
      }

      if (!this.fromCRUD && this.formInputs[key].itemsEndpoint != null && this.formInputs[key].items == null) {
        fetchV2('GET ' + this.formInputs[key].itemsEndpoint).then(items => {
          for (const i in items) {
            items[i] = transform.from(this.formInputs, items[i]);
          }
          this.formInputs[key].items = items.sort((a, b) => a.text < b.text ? -1 : a.text > b.text ? 1 : 0);
          if (this.formInputs[key].items.length > 0) {
            this.formModel[key] = this.formInputs[key].items[0].value;
          }
          this.inputKey = new Date().getTime();
        }).catch(err => {
          console.error(err);
        })
      }

      this.formInputs[key].infoMessage = null;

      this.setDisabledHidden(key, this.formMethod);

      if (this.formInputs[key].default !== undefined) {
        if (this.formInputs[key].type === 'date')
          this.formModel[key] = this.formInputs[key].default.substring(0, 10);
        else
          this.formModel[key] = this.formInputs[key].default;
      }
    }

    this.loadComplete = true;
  },
}
</script>
