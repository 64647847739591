<template>
  <div
    class="loader-v2"
    :style="`font-family: sans-serif;`"
  >
    <div class="loading-container">
      <div class="loading-text">
        <span>L</span>
        <span>O</span>
        <span>A</span>
        <span>D</span>
        <span>I</span>
        <span>N</span>
        <span>G</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LoaderV2',

  components: {

  },

  props: {

  },

  data: () => ({

  }),

  watch: {

  },

  computed: {

  },

  methods: {

  },

  destroyed() {

  },

  created() {

  },

  mounted() {

  }
}
</script>
<style scoped>
* {
  box-sizing: border-box;
}

.loader-v2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.loading-container {
  width: 100%;
  max-width: 520px;
  text-align: center;
  color: #fff;
  position: relative;
  margin: 0 32px;
  padding-bottom: 15px;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    background-color: #fff;
    left: 0;
    border-radius: 0px;
    animation: movingLine 2.4s infinite ease-in-out;
  }
}

@keyframes movingLine {
  0% {
    opacity: 0;
    width: 0;
  }

  33.3%, 66% {
    opacity: 0.8;
    width: 100%;
  }

  85% {
    width: 0;
    left: initial;
    right: 0;
    opacity: 1;
  }

  100% {
   opacity: 0;
   width: 0;
  }
}

.loading-text {
  font-size: 4vw;
  line-height: 4vw;
  letter-spacing: 10px;
  display: flex;
  justify-content: space-evenly;
  span {
    animation: moveLetters 2.4s infinite ease-in-out;
    position: relative;
    display: inline-block;
    opacity: 0;
    text-shadow: 0px 2px 10px rgba(46, 74, 81, 0.3);
  }
}

.loading-text span:nth-child(1) {
  animation-delay: 0s;
}
.loading-text span:nth-child(2) {
  animation-delay: 0.1s;
}
.loading-text span:nth-child(3) {
  animation-delay: 0.2s;
}
.loading-text span:nth-child(4) {
  animation-delay: 0.3s;
}
.loading-text span:nth-child(5) {
  animation-delay: 0.4s;
}
.loading-text span:nth-child(6) {
  animation-delay: 0.5s;
}
.loading-text span:nth-child(7) {
  animation-delay: 0.6s;
}

@keyframes moveLetters {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

</style>
