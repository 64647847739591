<template>
  <v-expansion-panels
    v-model="panel"
    multiple
    class="mt-5 mx-auto"
    style="width: 100%;"
    popout
    flat
    tile
    hover
  >
    <v-expansion-panel
      class="px-5 py-2"
    >
      <v-expansion-panel-header
        class="pa-0"
      >
        <h2
          class="mr-auto text-left d-flex align-center"
        >
          <v-badge
            v-if="error.length > 0 || warning.length > 0 || info.length > 0"
            :color="error.length > 0 ? 'error' : warning.length > 0 ? 'warning' : 'info'"
            :content="error.length + warning.length + info.length"
          >
            Payments Due & Alerts
          </v-badge>
        </h2>
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <!-- Overdue Payments -->
        <v-alert
          v-for="(item, i) in error"
          :key="'overduePayment' + i"
          style="width: 100%; cursor: pointer;"
          class="alert-box mb-1 pa-2 d-flex flex-row justify-space-between"
          color="error"
          type="error"
          @click="$router.push('/dashboard/' + $route.path.match(/member|manager/i)[0] + '/payment?type=' + item.type + '&id=' + item.id + '&property_id=' + item.property_id)"
        >
          <span>
            <b>{{ item.name }} - Click to Pay!</b>
          </span>

          &nbsp;-&nbsp;

          <span>${{ item.amount.toFixed(2) }}
            <span
              v-if="parseFloat(item.interest) > 1">({{ ((parseFloat(item.interest) - 1) * 100).toFixed(2) }}% compounded interest)</span>
          </span>

          &nbsp;-&nbsp;

          <span>{{ convertDate(item.due_date) }}</span>

          &nbsp;-&nbsp;

          <span>{{ item.lname }} &lt;{{ item.line1 }}&gt;</span>
        </v-alert>

        <!-- Upcoming Payments -->
        <v-alert
          v-for="(item, i) in warning"
          :key="'upcomingPayment' + i"
          style="width: 100%; cursor: pointer;"
          class="alert-box mb-1 pa-2 d-flex justify-space-between"
          color="warning"
          type="warning"
          @click="$router.push('/dashboard/' + $route.path.match(/member|manager/i)[0] + '/payment?type=' + item.type + '&id=' + item.id + '&property_id=' + item.property_id)"
        >
          <span>
            <b>{{ item.name }} - Click to Pay!</b>
          </span>

          &nbsp;-&nbsp;

          <span>${{ item.amount.toFixed(2) }}</span>

          &nbsp;-&nbsp;

          <span>{{ convertDate(item.due_date) }}</span>

          &nbsp;-&nbsp;

          <span>{{ item.lname }} &lt;{{ item.line1 }}&gt;</span>
        </v-alert>

        <!-- General Alerts -->
        <v-alert
          v-for="(item, i) in info"
          :key="'generalAlert' + i"
          style="width: 100%;"
          class="mb-1 pa-2 d-flex flex-row justify-space-between"
          type="info"
        >
          <b>{{ item.topic }}</b>

          &nbsp;-&nbsp;

          <span>{{ item.description }}</span>
        </v-alert>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'AlertsPanel',

  props: {
    error: {
      type: Array,
      required: false,
      default: () => { return Array() },
    },

    warning: {
      type: Array,
      required: false,
      default: () => { return Array() },
    },

    info: {
      type: Array,
      required: false,
      default: () => { return Array() },
    },
  },

  data: () => ({
    panel: [0],
  }),

  methods: {
    convertDate(d) {
      const d2 = new Date();
      d2.setFullYear(parseInt(d.slice(0, 4)), parseInt(d.slice(5, 7)) - 1, parseInt(d.slice(8, 10)));
      d2.setHours(0, 0, 0);
      return d2.toDateString().slice(4);
    },

    convertDateTime(d) {
      const d2 = new Date(d);
      d2.setFullYear(parseInt(d.slice(0, 4)), parseInt(d.slice(5, 7)) - 1, parseInt(d.slice(8, 10)));
      d2.setHours(parseInt(d.slice(11, 13)), parseInt(d.slice(14, 16)), parseInt(d.slice(17, 19)));
      return d2.toString().slice(4, 24)
    },
  },

  created() {

  }
};
</script>
<style>

</style>
