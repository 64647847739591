module.exports = {
  id: {
    label: 'ID',
    type: 'number',
    required: true,
    disabled: {
      edit: true,
    },
    hidden: {
      add: true,
      edit: true,
    },
  },
  property_id: {
    label: 'Lot #',
    hint: 'Start typing and select a property from the dropdown',
    type: 'autocomplete',
    itemsEndpoint: '/properties/personal/list',
    required: true,
  },
  check_in: {
    label: 'Check in',
    type: 'date',
    required: true,
    // inFuture: true,
  },
  check_out: {
    label: 'Check out',
    type: 'date',
    required: true,
    // inFuture: true,
  },
  nights: {
    label: '# of nights: ',
    type: 'calculated',
    calc: 'difference',
    termType: 'date',
    terms: ['check_out', 'check_in'],
    resultUnit: 'day',
  },
  f_and_f: {
    label: 'Non-Paying Family & Friends',
    type: 'switch',
  },
  guests: {
    label: 'Renter',
    type: 'list',
    hint: 'Name of PAYING renter or name of non-paying family/ friends',
    template: {
      fname: {
        label: 'First Name',
        required: true,
      },
      lname: {
        label: 'Last Name',
        required: true,
      },
    },
    min: 1,
    max: 1,
  },
  vehicles: {
    label: 'Vehicles',
    type: 'list',
    hint: 'List all vehicles entering MBRCC',
    template: {
      make: {
        label: 'Make',
        required: true,
      },
      model: {
        label: 'Model',
        required: true,
      },
      license_plate: {
        label: 'License Plate #',
        required: true,
      },
      region: {
        label: 'State/Province',
        required: true,
      }
    },
    min: 1,
  },
  amount: {
    label: 'Fee Total: ',
    type: 'if',
    terms: ['f_and_f', 'nights'],
    op: ['==', '<'],
    exp: [false, 30],
    prepend: '$',
    return: ['25', '0'],
  },
}
