<template>
  <div>
    <div
      class="label"
    >{{ item.label }}</div>

    <div
      v-if="!item.edit"
      class="d-flex mb-3"
    >
      <span
        style="width: 100%; min-width: 201px;"
      >{{ item.value }}</span>

      <v-icon
        class="ml-3"
        @click="item.edit=true"
      >mdi-pencil</v-icon>
    </div>

    <div
      v-else
      class="d-flex mb-3 pa-0"
    >
      <v-text-field
        v-model="item.value"
        dense
        hide-details
        class="pa-0"
        style="width: 100%;"
      />

      <v-icon
        class="ml-3"
        @click="item.edit=false; $emit('save', item.value)"
      >mdi-floppy</v-icon>
    </div>
  </div>
</template>

<script>
/* IMPORTS */

export default {
  name: 'TextEdit',

  components: {

  },

  props: {
    item: {
      type: Object,
      required: true,
    }
  },

  data: () => ({

  }),

  watch: {

  },

  methods: {

  },

  computed: {

  },

  created () {

  },

  mounted () {

  },
};
</script>

<style scoped>
  .v-text-field, .v-input--dense:not(.v-text-field--outlined), input {
    padding: 0 !important;
  }
  .v-input {
    margin: 0 !important;
  }
  .label {
    color: #505050;
    font-size: 14px;
  }
</style>
