<template>
  <div
    class="d-flex flex-column align-center ma-0 mx-auto"
  >
    <v-btn
      v-if="editForm===true"
      class="mx-auto mb-5"
      color="error"
      style="width: 175px;"
      large
      @click="deleteRental"
    >Remove Rental</v-btn>

    <v-btn
      v-else-if="editForm===false"
      class="mx-auto mb-5"
      color="accent"
      style="width: 175px;"
      large
      @click="downloadRental"
    >Download .csv</v-btn>

    <form-card
      :key="cardKey"
      class="mx-auto"
      formTitle="Rental Form"
      :permissions="permissions"
      :formInputs="formInputs"
      :formModel="formModel"
      :formMethod="formMethod"
      :loading="loading"
      :structure="structure"
      :saveButton="editForm !== false"
      style="max-width: 600px; width: 100%;"
      @save="submitForm"
    />
  </div>
</template>

<script>
/* IMPORTS */
import __STRUCTURE__ from '@/assets/structures/rentalForm.js';
import * as FormInputs from '@/assets/structures/rentalForm.js';
import FormCard from '@/components/crud-management/children/FormCard.vue';
import fetchV2 from '../../../assets/js/fetchV2';

export default {
  name: 'RentalForm',

  components: {
    FormCard,
  },

  props: {
    permissions: {
      type: Number,
      required: true,
    },

    propList: {
      type: Array,
      required: true,
    },

    rentals: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    formInputs: {},
    structure: Object,
    formModel: {},
    formMethod: 'add',
    editForm: null,
    loading: false,
    cardKey: 0,
  }),

  watch: {
    formModel: {
      handler(val) {
        if (this.editForm === null) {
          if (this.formModel.check_in && this.formModel.check_out && new Date(this.formModel.check_in).getTime() - new Date(this.formModel.check_out).getTime() > 0) {
            this.formInputs.check_out.errorMessages = ['Must be a later date than check in'];
          }
          else {
            this.formInputs.check_out.errorMessages = [];
          }
        }
      },
      deep: true,
    },

    rentals(val) {
      if (this.$route.params.rental_id != null && !isNaN(parseInt(this.$route.params.rental_id))) {
        this.getRental(parseInt(this.$route.params.rental_id));
      }
    },

    propList(val) {
      this.formInputs.property_id.items = val;
    }
  },

  methods: {
    populateForm(data) {
      const d = new Date();
      if (this.dateDiff(d.getFullYear() + '-' + (d.getMonth()+1).toString().padStart(2, '0') + '-' + d.getDate().toString().padStart(2, '0'), data.check_out) <= 0) {
        this.editForm = true;
        for(const key of Object.keys(this.formInputs)) {
          this.formInputs[key].disabledOverride = false;
          this.formInputs[key].default = data[key];
        }
      }
      else {
        this.editForm = false;
        for(const key of Object.keys(this.formInputs)) {
          this.formInputs[key].disabledOverride = true;
          this.formInputs[key].default = data[key];
        }
      }

      this.formMethod = 'edit';
      this.cardKey = new Date().getTime();
    },



    dateDiff(d1, d2) {
      let val = 0;
      val += (parseInt(d1.substr(0, 4)) - parseInt(d2.substr(0, 4))) * 365;
      val += (parseInt(d1.substr(5, 2)) - parseInt(d2.substr(5, 2))) * 31;
      val += (parseInt(d1.substr(8, 2)) - parseInt(d2.substr(8, 2))) * 1;
      return val;
    },

    submitForm() {
      if (this.formModel.property_id == null) {
        return this.$emit('error', 'You need to select a property for this rental.');
      }

      if (this.$route.params.rental_id != null) {
        if (isNaN(this.$route.params.rental_id) || this.$route.params.rental_id < 0) {
          return this.$emit('error', 'Invalid rental ID');
        }
        return this.putRental();
      }
      else {
        return this.postRental();
      }
    },

    getRental(id) {
      this.$emit('loading', 'Fetching rental data');
      fetchV2('GET /rentals/personal/' + id).then((result) => {
        this.populateForm(result);
        this.$emit('loading', false);
      }).catch((err) => {
        this.$emit('error', err.error ? err.error : 'An error has occurred. Please contact ' + process.env.VUE_APP_SUPPORT_EMAIL + '.');
      })
    },

    postRental() {
      this.$emit('loading', 'Creating rental');
      fetchV2('POST /rentals/personal', this.formModel).then((result) => {
        this.$emit('success', {
          message: 'Your rental record has been created. A confirmation email has been sent to your email.',
          postCall: 'refresh-rentals',
          postPage: `/dashboard/${this.$route.path.match(/member|manager/i)[0]}/rental-form/${result.rental_id}`
        })
      }).catch((err) => {
        this.$emit('error', err.error ? err.error : 'An error has occurred. Please contact ' + process.env.VUE_APP_SUPPORT_EMAIL + '.');
      })
    },

    putRental() {
      this.$emit('loading', 'Updating rental');
      fetchV2('PUT /rentals/personal/' + this.$route.params.rental_id, this.formModel).then(() => {
        this.$emit('success', {
          message: 'Your rental record has been updated. A confirmation email has been sent to your email.',
          postCall: 'refresh-rentals'
        })
      }).catch((err) => {
        this.$emit('error', err.error ? err.error : 'An error has occurred. Please contact ' + process.env.VUE_APP_SUPPORT_EMAIL + '.');
      })
    },

    deleteRental() {
      this.$emit('loading', 'Deleting rental');
      fetchV2('DELETE /rentals/personal/' + this.$route.params.rental_id).then(() => {
        this.$emit('success', {
          message: 'Your rental record has been deleted.',
          postCall: 'refresh-rentals',
          postPage: '/dashboard/' + this.$route.path.match(/member|manager/i)[0] + '/rentals'
        })
      }).catch((err) => {
        this.$emit('error', err.error ? err.error : 'An error has occurred. Please contact ' + process.env.VUE_APP_SUPPORT_EMAIL + '.');
      })
    },

    downloadRental() {
      let csv = '\uFEFF'; // Universal Byte Order Mark (BOM)

      // Populate headers
      Object.keys(this.formInputs).forEach((key) => {
        csv += key + ','
      });
      csv = csv.slice(0, -1) + '\r\n';

      // Populate data
      Object.keys(this.formInputs).forEach((key) => {
        if (this.formInputs[key] && this.formInputs[key].type === 'list') {
          let a = Array();
          this.formModel[key].forEach((item) => {
            let s = '';
            Object.keys(item).forEach((key2) => {
              s += item[key2] + ' ';
            })
            a.push(s.slice(0, -1));
          });
          csv += JSON.stringify(a) + ','
        }
        else
          csv += this.formModel[key] + ','
      });
      csv = csv.slice(0, -1) + '\r\n';

      // Create file and trigger download
      let e = document.createElement('a');
      e.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      e.target = '_blank';
      e.download = 'rental-' + this.formInputs.property_id.items.filter((e) => { return e.value === this.formModel.property_id })[0].text.replace(/0x20/gi, '_') + '.csv';
      e.click();
    },
  },

  created() {
    this.structure = __STRUCTURE__;
    this.formInputs = FormInputs;
    for (const key of Object.keys(this.formInputs)) {
      this.formInputs[key].default = undefined;
    }

    if (this.$route.params.rental_id != null && !isNaN(parseInt(this.$route.params.rental_id))) {
      this.getRental(parseInt(this.$route.params.rental_id));
    }
    else {
      this.editForm = null;
      for(const key of Object.keys(this.formInputs)) {
        this.formInputs[key].disabledOverride = false;
      }
      this.formMethod = 'add';
      this.cardKey = new Date().getTime();
    }
  },
};
</script>
