import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

const lightTheme = {
  primary: `#${process.env.VUE_APP_COLOR_PRIMARY}`,
  secondary: `#${process.env.VUE_APP_COLOR_SECONDARY}`,
  accent: `#${process.env.VUE_APP_COLOR_ACCENT}`,
  error: `#${process.env.VUE_APP_COLOR_ERROR}`,
  warning: `#${process.env.VUE_APP_COLOR_WARNING}`,
  info: `#${process.env.VUE_APP_COLOR_INFO}`,
  gray: `#${process.env.VUE_APP_COLOR_GRAY}`,
}

export default new Vuetify({
  theme: {
    themes: {
      light: lightTheme,
    },
  },
});
