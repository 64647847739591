module.exports = {
  password: {
    label: 'New Password',
    type: 'password',
    required: true,
  },
  confirm_password: {
    label: 'Confirm Password',
    type: 'password',
    required: true,
  },
}