<template>
  <v-card
    id="iframeContainer"
    class="mx-auto pa-5 d-flex flex-column-reverse"
    style="height: 100%;"
  >

    <!-- iFrame is inserted here -->

    <v-btn
      color="error"
      class="mx-auto mt-5"
      style="width: 120px;"
      @click="$emit('closeDialog');"
    >
      Close
    </v-btn>

  </v-card>
</template>

<script>
/* IMPORTS */

export default {
  name: 'IframeDialog',

  components: {

  },

  props: {
    html: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    header: null,
  }),

  watch: {
    emailBody() {
      createIframe();
    }
  },

  methods: {
    createIframe() {
      this.clearIframeContainer();

      let iframe = document.createElement('iframe');
      document.getElementById('iframeContainer').appendChild(iframe);

      iframe.setAttribute("style","height: " + (document.getElementsByTagName('main')[0].children[0].clientHeight - 200) + "px; width: 100%; margin: 0; padding:0;");
      iframe.contentWindow.document.open();
      iframe.contentWindow.document.write(this.html);
      iframe.contentWindow.document.close();
    },

    clearIframeContainer() {
      while (document.getElementById('iframeContainer').children.length > 1)
        document.getElementById('iframeContainer').removeChild(document.getElementById('iframeContainer').children[1])
    }
  },

  computed: {

  },

  created () {

  },

  mounted () {
    setTimeout(() => {
      this.createIframe();
    }, 0)
  },
};
</script>

<style scoped>

</style>
