<template>
  <div></div>
</template>

<script>
import fetchV2 from '../assets/js/fetchV2';

export default {
  name: 'Reset',

  methods: {
    lsTest() {
      let test = 'test';
      try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
      } catch(e) {
        console.error('ERROR: Local Storage is not supported in this browser.');
        return false;
      }
    },

    reset() {
      fetchV2('POST /users/reset', {
        ref: this.$route.query.ref.trim().toLowerCase(),
        w: this.$route.query.w,
        x: this.$route.query.x,
        y: this.$route.query.y,
        z: this.$route.query.z,
      }).then(data => {
        console.log(data);
        if (data != null && data.permissions != null) {
          this.$emit('permissions', data.permissions);
          this.$router.replace('/dashboard/change-password')
        }
      }).catch((err) => {
        console.error(err);
        this.loading = false;
        this.$emit('error', 'Error resetting password.');
      })
    }
  },

  created () {
    this.reset();
  },
};
</script>
