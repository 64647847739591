module.exports = {
  users: {
    id: {
      label: 'ID',
      type: 'number',
      disabled: {
        edit: true,
      },
      hidden: {
        add: true,
        edit: true,
      },
      required: true,
    },
    fname: {
      label: 'First Name',
      type: 'text',
      required: true,
    },
    lname: {
      label: 'Last Name',
      type: 'text',
      required: true,
    },
    email: {
      label: 'Email',
      type: 'email',
      required: false,
      email: true,
      disabled: {
        edit: true,
      }
    },
    email2: {
      label: 'Email #2',
      type: 'email',
      required: false,
      email: true,
    },
    email3: {
      label: 'Email #3',
      type: 'email',
      required: false,
      email: true,
    },
    phone: {
      label: 'Phone',
      type: 'tel',
      required: false,
      prefix: '+',
      placeholder: '1 (555) 555-5555',
      phone: true,
      hidden: {
        add: true,
      },
    },
    phone2: {
      label: 'Phone #2',
      type: 'tel',
      required: false,
      prefix: '+',
      placeholder: '1 (555) 555-5555',
      phone: true,
      hidden: {
        add: true,
      },
    },
    phone3: {
      label: 'Phone #3',
      type: 'tel',
      required: false,
      prefix: '+',
      placeholder: '1 (555) 555-5555',
      phone: true,
      hidden: {
        add: true,
      },
    },
    mailing_line1: {
      label: 'Mailing Address Line 1',
      type: 'text',
      required: false,
      hidden: {
        add: true,
      },
    },
    mailing_line2: {
      label: 'Mailing Address Line 2',
      type: 'text',
      required: false,
      hidden: {
        add: true,
      },
    },
    mailing_city: {
      label: 'Mailing City',
      type: 'text',
      required: false,
      hidden: {
        add: true,
      },
    },
    mailing_state: {
      label: 'Mailing State/Province',
      type: 'text',
      required: false,
      hidden: {
        add: true,
      },
    },
    mailing_country: {
      label: 'Mailing Country',
      type: 'combobox',
      items: [
        { text: 'USA', value: 'USA', },
        { text: 'Canada', value: 'CA' },
      ],
      defaultValue: 'USA',
      required: false,
      hidden: {
        add: true,
      },
    },
    mailing_zip: {
      label: 'Mailing Zip',
      type: 'text',
      required: false,
      hidden: {
        add: true,
      },
    },
    license_plates: {
      label: 'License Plates at Lot',
      type: 'text',
      hint: 'Add State/Province after number, separate plates with a comma',
      required: false,
      hidden: {
        add: true,
      }
    },
    email_mbr_communications: {
      label: 'MBR Communication',
      type: 'select',
      items: [
        { text: 'True', value: 1 },
        { text: 'False', value: 0 },
      ],
      hidden: {
        add: true,
        edit: true,
      },
      disabled: {
        add: true,
        edit: true,
      },
      send: false,
    },
    email_agm_notice: {
      label: 'AGM Notice',
      type: 'select',
      items: [
        { text: 'True', value: 1 },
        { text: 'False', value: 0 },
      ],
      hidden: {
        add: true,
        edit: true,
      },
      disabled: {
        add: true,
        edit: true,
      },
      send: false,
    },
    phone_listed: {
      label: 'Phone Listed',
      type: 'select',
      items: [
        { text: 'True', value: 1 },
        { text: 'False', value: 0 },
      ],
      hidden: {
        add: true,
        edit: true,
      },
      disabled: {
        add: true,
        edit: true,
      },
      send: false,
    },
  },
}
