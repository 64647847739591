<template>
  <div>
    <h1
      class="mt-5 px-3"
    >Resend Activation</h1>

    <v-card
      v-if="users"
      style="max-width: 500px;"
      class="mx-auto d-flex flex-column align-center"
      flat
    >
      <v-btn
        class="my-5 mx-auto"
        color="red"
        style="color: white;"
        @click="resend()"
      >Resend All</v-btn>

      <v-text-field
        v-model="search"
        label="Search"
        style="width: 100%;"
        @change="filter"
      />

      <table
        class="mx-auto px-0 mb-10"
        accordion
        style="width: 100%;"
      >
        <thead
          style="width: 100%;"
        >
          <tr
            class="d-flex align-center justify-space-between"
            style="width: 100%"
          >
            <th
              class="text-left"
              style="width: 100%;"
            >User</th>

            <th
              style="width: 30%;"
            >Action</th>
          </tr>
        </thead>

        <tbody
          style="width: 100%;"
        >
          <tr
            v-for="(u, i) in filteredUsers"
            :key="'user' + i"
            class="d-flex align-center justify-space-between"
            style="width: 100%;"
          >
            <td
              class="text-left"
              style="width: 100%;"
            >
              <div
                style="overflow-wrap: anywhere;"
              >{{ u.fname }} {{ u.lname }}</div>

              <div
                style="overflow-wrap: anywhere;"
              >{{ u.email }}</div>
            </td>

            <td
              class="d-flex justify-center"
              style="width: 30%;"
            >
              <v-btn
                @click="resend(u)"
                color="primary"
              >Resend</v-btn>
            </td>
          </tr>
        </tbody>
      </table>
    </v-card>
  </div>
</template>

<script>
/* IMPORTS */
import fetchV2 from '../../../assets/js/fetchV2';

export default {
  name: 'ResendActivation',

  components: {

  },

  props: {
    permissions: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    users: null,
    filteredUsers: [],
    search: '',
  }),

  watch: {

  },

  computed: {

  },

  methods: {
    filter() {
      const filteredUsers = [];
      for (const user of this.users) {
        if (
          user.fname.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 ||
          user.lname.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 ||
          user.email.toLowerCase().indexOf(this.search.toLowerCase()) >= 0
        ) {
          filteredUsers.push(user);
        }
      }
      this.filteredUsers = filteredUsers;
    },

    getUnactivatedUsers() {
      this.$emit('loading', 'Getting unactivated users');
      fetchV2('GET /users/unactivated').then(data => {
        this.users = data;
        this.$emit('loading', false);
        this.filter();
        this.$forceUpdate();
      })
      .catch((err) => {
        this.$emit('error', 'An error has occurred. Please contact ' + process.env.VUE_APP_SUPPORT_EMAIL + '.');
      })
    },

    resend(user) {
      if (user === undefined) user = {};
      this.$emit('loading', 'Resending activation emails');
      fetchV2('POST /users/resend', user).then(data => {
        this.$emit('success', 'Activation emails have been sent to ' + data + '.');
        this.getUnactivatedUsers();
      })
      .catch((err) => {
        this.$emit('error', 'An error has occurred. Please contact ' + process.env.VUE_APP_SUPPORT_EMAIL + '.');
      })
    },
  },

  created () {
    this.getUnactivatedUsers();
  }
}
</script>

<style scoped>
  tr {
    padding: 0 6px;
  }
  tr:nth-child(odd) {
    background: #F0F0F0;
  }
</style>
