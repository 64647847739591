<template>
  <div
    class="pt-10"
  >
    <v-btn
      fab
      small
      style="position: absolute; top: 15px; left: 15px; z-index: 100;"
      @click="$router.back()"
      color="accent"
    >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>

    <form-card
      class="mx-auto"
      formTitle="Change Password"
      :permissions="permissions"
      :formInputs="formInput"
      :formModel="formModel"
      :loading="loading"
      flat
      @save="submitForm"
      style="max-width: 360px;"
    />
  </div>
</template>

<script>
/* IMPORTS */
import * as FormInputs from '@/assets/structures/changePassword.js';
import FormCard from '@/components/crud-management/children/FormCard.vue';
import fetchV2 from '../../assets/js/fetchV2';

export default {
  name: 'ChangePassword',

  components: {
    FormCard,
  },

  props: {
    permissions: {
      type: Number,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    formInput: {},
    formModel: {},
  }),

  methods: {
    submitForm: async function(e) {
      if (this.formModel.password !== this.formModel.confirm_password) {
        this.$emit('error', 'Passwords do not match.')
        return;
      }
      fetchV2('PUT /users/change-password', this.formModel).then(() => {
        this.$emit('success', 'Password updated!');
        this.$router.push('/');
      })
      .catch((err) => {
        this.$emit('error', 'An error occurred. Please contact ' + process.env.VUE_APP_SUPPORT_EMAIL + '.');
      })
    }
  },

  created() {
    this.formInput = FormInputs;
  },
};
</script>
