<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
  >
    <form-card
      :permissions="permissions"
      :formTitle="formTitle"
      :formInputs="formInputs"
      :formModel="formModel"
      :formMethod="formMethod"
      :loading="loading"
      :flat="!show"
      :closeButton="true"
      :fromCRUD="fromCRUD"
      @error="$emit('error', event)"
      @close="$emit('close', $event)"
      @save="$emit('save', $event)"
      @reload="$emit('reload', true)"
      @show="opacity=1"
      @hide="opacity=0"
    />
  </v-dialog>
</template>

<script>
import FormCard from './FormCard.vue';

export default {
  name: 'FormDialog',

  components: {
    FormCard,
  },

  props: {
    permissions: {
      type: Number,
      required: true,
    },

    formTitle: {
      type: String,
      required: true,
    },

    formInputs: {
      type: Object,
      required: true,
    },

    formModel: {
      type: Object,
      required: true,
    },

    formMethod: {
      type: String,
      required: false,
      default: 'add',
    },

    loading: {
      type: Boolean,
      required: true,
    },

    flat: {
      type: Boolean,
      required: false,
      default: false,
    },

    closeButton: {
      type: Boolean,
      required: false,
      default: false,
    },

    fromCRUD: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    dialog: true,
    opacity: 0,
    show: false,
  }),

  watch: {
    dialog(val) {
      if (!val) {
        this.$emit('close');
      }
    }
  },
};
</script>
