<template>
  <div
    class="ma-0 mx-auto"
    style="min-height: 100%; max-width: 1000px;"
  >
    <h1
      class="mt-0 mb-5"
      style="color: white;"
    >My Account</h1>

    <alerts-panel
      v-if="overduePayments.length > 0 || upcomingPayments.length > 0 || generalAlerts.length > 0"
      :error="overduePayments"
      :warning="upcomingPayments"
      :info="generalAlerts"
    />

    <div
      class="d-flex flex-column flex-md-row justify-center mx-auto" style="width: 100%;"
    >
      <div
        class="d-flex flex-column flex-wrap" style="width: 100%;"
      >
        <v-card
          class="mt-5 mx-auto pa-5"
          style="width: 100%;"
        >
          <div
            style="width: 100%;"
            class="d-flex flex-row jusity-space-between align-center mb-3"
          >
            <h2
              class="mr-auto text-left"
            >Personal Info</h2>

            <v-btn
              outlined
              color="primary"
              small
              @click="newPersonalModel = Object.assign({}, personalModel); personalDialog=true"
              class="pl-3 ml-auto mr-0 mt-0"
            >
              <v-icon
                small
                class="mr-1"
              >mdi-pencil</v-icon>
              <span>Edit</span>
            </v-btn>
          </div>

          <div
            v-if="personalModel"
            class="d-flex flex-column justify-space-between
            flex-md-row"
            style="line-height: 1.7rem; font-family: var(--heading-font) !important;"
          >
            <div>
              <h3
                class="text-left"
                style="font-weight: bold; color: #222;"
              >{{ personalModel.fname }} {{ personalModel.lname }}</h3>
              <div>{{ getPerms(permissions) }}</div>

              <div
                v-if="personalModel.email"
              >{{ personalModel.email }}</div>

              <div
                v-if="personalModel.email2"
              >{{ personalModel.email2 }}</div>

              <div
                v-if="personalModel.email3"
              >{{ personalModel.email3 }}</div>

              <div
                v-if="personalModel.phone"
              >+{{ getPhone(personalModel.phone, 'phone') }}</div>

              <div
                v-if="personalModel.phone2"
              >+{{ getPhone(personalModel.phone2, 'phone2') }}</div>

              <div
                v-if="personalModel.phone3"
              >+{{ getPhone(personalModel.phone3, 'phone3') }}</div>

              <br>

              <h3
                class="text-left"
                style="font-weight: bold; color: #222;"
              >License Plates</h3>

              <div
                v-if="personalModel.license_plates"
              >{{ personalModel.license_plates }}</div>
            </div>

            <v-spacer />

            <div
              class="text-left text-md-right mt-5 mt-md-0"
            >
              <h3
                class="text-md-right text-left"
                style="font-weight: bold; color: #222;"
              >Mailing Address</h3>

              <div
                v-if="personalModel.mailing_line1 && personalModel.mailing_city || personalModel.mailing_state || personalModel.mailing_country || personalModel.mailing_zip"
              >
                <div>{{ personalModel.mailing_line1 }}</div>

                <div
                  v-if="personalModel.mailing_line2"
                >{{ personalModel.mailing_line2 }}</div>

                <div>{{ personalModel.mailing_city }}, {{ personalModel.mailing_state }} {{ personalModel.mailing_country }} {{ personalModel.mailing_zip }}</div>
              </div>
            </div>
          </div>
        </v-card>

        <v-card
          v-if="properties.length > 0"
          class="mt-5 mx-auto pa-5"
          style="width: 100%;"
        >

          <h2
            class="ml-0 mb-3 mr-auto text-left"
          >Properties</h2>

          <v-expansion-panels
            class="mx-auto px-0"
            style="width: 100%;"
            flat
            tile
            hover
          >
            <v-expansion-panel
              v-for="(p, i) in properties"
              :key="'property' + i"
              readonly
              @click="$router.push('/dashboard/' + $route.path.match(/member|manager/i)[0] + '/property/' + p.id)"
            >
              <v-expansion-panel-header
                expand-icon="mdi-open-in-new"
                color="#E6E6E6"
              >
                <div
                  v-if="propertyHeight==100"
                >#{{ p.lot }}<br>{{ p.line1 }}<br>{{ p.line2 }}<br>{{ p.city }}, {{ p.state }} {{ p.country }}, {{ p.zip }}</div>

                <div
                  v-else
                >#{{ p.lot }}&nbsp;-&nbsp;{{ p.line1 }} {{ p.line2 }} {{ p.city }}, {{ p.state }} {{ p.country }}, {{ p.zip }}</div>
              </v-expansion-panel-header>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>

      </div>

      <div
        class="d-flex align-stretch"
        style="min-width: 280px;"
      >
        <v-card
          class="d-flex flex-column jusitfy-space-between mt-5 ml-0 ml-md-5 pa-5 px-5"
          style="width: 100%;"
        >
          <div
            style="height: 100%;"
          >
            <h2
              class="ml-0 mb-3 mr-auto text-left"
            >Settings</h2>

            <h4
              class="text-left"
            >Email Opt-ins</h4>

            <hr>

            <v-checkbox
              v-model="settings.email_mbr_communications"
              label="MBR Communications"
              hide-details
              class="my-2"
            />

            <v-checkbox
              v-if="permissions!==2"
              v-model="settings.email_agm_notice"
              label="AGM Notice"
              hide-details
              class="my-2"
            />

            <v-checkbox
              v-model="settings.email_paperless_billing"
              label="Paperless Billing"
              hide-details
              class="my-2"
            />

            <h4
              v-if="permissions!==2"
              class="text-left mt-5"
            >Phone & Email Sharing</h4>

            <hr
              v-if="permissions!==2"
            >

            <div
              v-if="permissions!==2"
              class="mt-3"
              style="max-width: 500px;
              font-size: 14px;"
            >Per RCW 64.038.045, a member can request names and mailing addresses of all members. Click below if you also want your email addresses and phone numbers shared when such a request is made.</div>

            <v-checkbox
              v-if="permissions!==2"
              v-model="settings.phone_listed"
              label="Shared when requested by a member"
              hide-details
              class="my-2"
            />
          </div>

          <v-btn
            class="mt-8"
            color="primary"
            block
            @click="saveSettings()"
          >Save</v-btn>
        </v-card>
      </div>

      <form-dialog
        v-if="personalDialog"
        :permissions="permissions"
        :formTitle="'Personal Info'"
        :formInputs="personalInput"
        :formModel="newPersonalModel"
        formMethod="edit"
        :loading="personalLoading"
        @save="save()"
        @close="personalDialog=false"
      />

    </div>
  </div>
</template>

<script>
/* IMPORTS */
import { setPerms } from '@/assets/js/constants.js';
import formInputs from '@/assets/structures/updateMember.js';

import TextEdit from '@/components/TextEdit.vue';
import FormDialog from '@/components/crud-management/children/FormDialog.vue';
import AlertsPanel from '@/components/AlertsPanel.vue';
import fetchV2 from '../../../assets/js/fetchV2';

export default {
  name: 'MyInfo',

  components: {
    TextEdit,
    FormDialog,
    AlertsPanel,
  },

  props: {
    permissions: {
      type: Number,
      required: true,
    },

    personalModel: {
      type: Object,
      required: true,
    },

    settings: {
      type: Object,
      required: true,
    },

    properties: {
      type: Array,
      required: true,
    },

    overduePayments: {
      type: Array,
      required: false,
      default: [],
    },

    upcomingPayments: {
      type: Array,
      required: false,
      default: [],
    },

    generalAlerts: {
      type: Array,
      required: false,
      default: [],
    },
  },

  data: () => ({
    windowWidth: 0,
    propertyHeight: 36,
    propertyPanels: [],
    newPersonalModel: null,
    personalDialog: false,
    personalLoading: false,
    personalInput: {},
  }),

  watch: {
    properties(val) {
      if (this.properties.length <= 3)
        this.propertyPanels = [0];
    }
  },

  methods: {
    saveSettings() {
      this.settings.id = this.personalModel.id;

      this.$emit('loading', 'Saving user settings');
      fetchV2('PUT /users/personal/settings', this.settings).then((data) => {
        this.email_consent = data.email_consent === 0 ? false : true;
        this.paperless_billing_consent = data.paperless_billing_consent === 0 ? false : true;
        this.$emit('loading', false);
        this.$emit('success', 'Your settings have been updated!');
      }).catch((err) => {
        console.error(err);
        this.$emit('loading', false);
        this.$emit('error', 'An error occurred. Please contact ' + process.env.VUE_APP_SUPPORT_EMAIL + '.');
      })
    },

    save() {
      this.personalDialog = false;
      let body = Object.assign({}, this.newPersonalModel);
      if (body.phone !== null) body.phone = body.phone.replace(/[\x28\x29\x20\x2b\x2d]/g, '');
      if (body.phone2 !== null) body.phone2 = body.phone2.replace(/[\x28\x29\x20\x2b\x2d]/g, '');
      if (body.phone3 !== null) body.phone3 = body.phone3.replace(/[\x28\x29\x20\x2b\x2d]/g, '');

      this.$emit('loading', 'Saving user information');
      fetchV2('PUT /users/personal', body).then((data) => {
        this.personalModel = Object.assign(this.personalModel, body);
        this.$emit('loading', false);
      }).catch((err) => {
        console.error(err);
        this.$emit('loading', false);
        this.$emit('error', 'An error occurred. Please contact ' + process.env.VUE_APP_SUPPORT_EMAIL + '.');
      })
    },

    getPerms(val) {
      let s = Object.keys(setPerms).find((key) => {
        return setPerms[key] === val;
      });
      if (s != null)
        return s.charAt(0).toUpperCase() + s.substring(1, s.length).toLowerCase();
      else
        return null
    },

    getPhone(val, key) {
      if (val) {
        let f = val.replace(/[\x2b\x28\x29\x2d\x20]/g, '');
        let s = '';
        for (let i = 0; i < f.length; i++) {
          if (i === 1) s += ' ('; if (i === 4) s += ') '; if (i === 7) s += '-';
          s += f[i];
        }
        this.personalModel[key] = s;
      }
      return this.personalModel[key];
    },

    onResize() {
      this.windowWidth = document.body.clientWidth;
      if (this.windowWidth < 700) {
        this.propertyHeight = 100;
      }
      else {
        this.propertyHeight = 36;
      }
    }
  },

  destroyed() {
    window.removeEventListener('resize', this.onResize)
  },

  created () {
    window.addEventListener('resize', this.onResize)

    this.onResize();
    this.personalInput = formInputs.users;
    if (this.properties.length <= 3) {
      this.propertyPanels = [0];
    }
  },

  mounted() {

  }
};
</script>

<style scoped>
  .gradient-background {
    background-image: linear-gradient(270deg,  var(--gray) 0%, var(--primary) 100% );
  }
  .alert-box {
    cursor: pointer;
  }
  .alert-box:hover {
    opacity: .75;
  }
</style>
